import Cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import { DEFAULT_LOCATION, LOCATION_SELECTED } from '../config/setup/setup';
import { setLocation } from '../redux/actions/locations';
import { getNavigation } from '../redux/actions/navigation';
import { makeUrlAbsolute, redirectToURL } from './url';

export function getLocationShortName(location) {
  if (location && location.shortName) {
    return location.shortName;
  } else {
    return DEFAULT_LOCATION[process.env.NEXT_PUBLIC_SITE || 'wowcher']
      .shortName;
  }
}
export const onSelectLocationHelper = (
  locationToSet,
  dispatch,
  router,
  navigatToLoc = false,
  override = false,
) => {
  dispatch(setLocation(locationToSet, true));
  dispatch(getNavigation(locationToSet));
  if (navigatToLoc && typeof window !== 'undefined') {
    const url = makeUrlAbsolute(`deals/${locationToSet.shortName}`);
    window.location.href = url;
    redirectToURL(url, router);
    localStorage.setItem(LOCATION_SELECTED, 'set');
  }
  // Check and Set the location  value to the select field value of the wowcher LightBox
  setTimeout(() => handleLightboxLocation(override), 300);
};

export const handleLightboxLocation = (override) => {
  if (Cookies.load(COOKIES.googleLocation) !== 'b') return;
  const iframe = Array.from(document.querySelectorAll('iframe')).find((f) =>
    f.id?.startsWith('lightbox-iframe-'),
  );

  if (iframe?.contentWindow?.document) {
    const selectElement = iframe.contentWindow.document.querySelector(
      '#form_input_custom3',
    );
    if (selectElement && (override || !selectElement.value)) {
      selectElement.value =
        Cookies.load(COOKIES.location)?.shortName || getLocationShortName(null);
      selectElement.dispatchEvent(new Event('change', { bubbles: true }));
    }
  } else {
    const selectElement = document.querySelector('#form_input_custom3');
    if (selectElement && (override || !selectElement.value)) {
      selectElement.value =
        Cookies.load(COOKIES.location)?.shortName || getLocationShortName(null);
      selectElement.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }
};

export const observeLightbox = () => {
  new MutationObserver((mutations, observer) => {
    if (mutations.some((m) => m.type === 'childList')) {
      const iframe = document.querySelector('iframe[id^="lightbox-iframe-"]');
      if (iframe) {
        handleLightboxLocation();
        observer.disconnect();
      }
    }
  }).observe(document.body, { childList: true, subtree: true });
};
