/* eslint-disable consistent-return, sonarjs/cognitive-complexity, import/no-unassigned-import, react-hooks/rules-of-hooks, prefer-spread, complexity */
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';
import cookies from 'react-cookies';
import useSWR from 'swr';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import { SHOW_PRICE_TYPES } from '../config/constants/deals';
import {
  DEAL_LOCATION_TYPES,
  PAGE_TYPES,
  PAGE_QUERY,
} from '../config/constants/page-types';
import COOKIES from '../config/cookies/cookies';
import {
  MAX_TEXT_DEAL_SIZE,
  URLSOCIALCUE,
  URLDEALIMPRESSION,
  URLPURCHASEVIEW,
  SHOW_REVIEWS,
  MIN_REVIEW_AVERAGE,
  URLEVERGREEN,
  DEALS_PROMO_TYPES,
  SHOW_TRAVEL_REVIEWS,
  CYF_DEAL_TYPE,
} from '../config/setup/setup';
import { NOW, SAVE, FREE, FROM } from '../config/text/text';
import { trackEvent, pointContinuousTrackingData } from './analytics';
import { arePricesDifferent } from './chooseYourOptions';
import { showNationalReviews } from './cookieSetter';
import { formatCurrency } from './currency';
import { getAppPlatform } from './device';
import httpCommonHeaders from './httpCommonHeaders';
import { isWowcherBrand } from './pages';
import { getRedTextProductSocialCue } from './socialCues';
import { stripOrigin, parseWowcherPath, slugAsSource } from './url';
import useClientSideEffect from './useClientSideEffect';
import {
  getUserLocationShortName,
  isUserInBookingCalendarOnRedemptionGiftTest,
  isUserInBookingCalendarOnRedemptionTest,
} from './user';
import 'intersection-observer';

//* * If deal has multiple options -- ie dropdown display mode (we show a popup) */
export const getIsChooseYourOptionsDeal = (deal) => {
  if (!deal || !deal.products || !deal.productDisplay) {
    return false;
  }

  return deal.products.length > 1 && deal.productDisplay.type === 'dropdown';
};

//* * If deal has multiple products (we show a popup) */
export const getIsChooseYourProductDeal = (deal) => {
  if (!deal || !deal.products) {
    return false;
  }

  return deal.products.length > 1 && deal.productDisplay.type === 'default';
};

export const isBookingCalendarOnRedemptionGiftDeal = (deal) => {
  if (!deal || !deal.productDisplay) {
    return false;
  }

  return (
    deal.productDisplay.type === 'calendar' &&
    deal.bookingCalendarOnRedemption === true &&
    isUserInBookingCalendarOnRedemptionGiftTest() === true
  );
};

export const isBookingCalendarOnRedemptionBasketGiftProduct = (
  deal,
  product,
) => {
  if (!deal || !deal.productDisplay) {
    return false;
  }

  return (
    deal.productDisplay.type === 'calendar' &&
    deal.bookingCalendarOnRedemption === true &&
    product.checkInDate === null
  );
};

export const isBookingCalendarOnRedemptionDeal = (deal) => {
  if (!deal || !deal.productDisplay) {
    return false;
  }

  return (
    deal.productDisplay.type === 'calendar' &&
    deal.bookingCalendarOnRedemption === true &&
    isUserInBookingCalendarOnRedemptionTest() === true
  );
};

//* * If deal has choose availability (we show a popup) */
export const isBookingCalendarChooseDateDeal = (deal) => {
  if (!deal || !deal.productDisplay) {
    return false;
  }

  return (
    deal.productDisplay.type === 'calendar' &&
    !isBookingCalendarOnRedemptionDeal(deal)
  );
};

export const getIsLeadGen = (deal) => {
  if (!deal) {
    return false;
  }

  return Boolean(deal.leadGen);
};

export const getIsCalendarDeal = (deal) => {
  return deal?.productDisplay?.type === 'calendar';
};

export const getIsCYFDeal = (deal) => {
  return deal.dealType === CYF_DEAL_TYPE;
};

export const getIsMhu = (deal) => {
  return deal?.mhu === true;
};

// Check if this is a defaulted deal, basically checks all the addtional types of deals
export const getIsDefaultDeal = (deal) => {
  if (
    getIsChooseYourProductDeal(deal) ||
    getIsLeadGen(deal) ||
    isBookingCalendarChooseDateDeal(deal) ||
    isBookingCalendarOnRedemptionDeal(deal) ||
    getIsChooseYourOptionsDeal(deal)
  ) {
    return false;
  }

  return true;
};

export const isDepositDeal = (deal) => {
  if (deal?.depositPrice || deal?.depositAvailable) return true;

  return false;
};

export const isProductPageInValid = (
  deal,
  productId,
  dealIdIndex,
  productIdIndex,
  isEvergreen,
  isNationalDeal,
) => {
  const productExists = deal?.products?.some(
    (product) => product.id === Number.parseInt(productId),
  );

  const isChooseYourOptions = getIsChooseYourOptionsDeal(deal);
  const isChooseYourProductDeal = getIsChooseYourProductDeal(deal);

  if (productId && !isNationalDeal) {
    return true;
  }
  if (
    !productExists ||
    productIdIndex - dealIdIndex !== 1 ||
    (productIdIndex - dealIdIndex === 1 &&
      !isChooseYourOptions &&
      !isChooseYourProductDeal &&
      !isEvergreen)
  ) {
    return true;
  }

  return false;
};

export const splatPriceDisplayEstimatedText = ({ deal }) => {
  const showDiscountSplat = cookies.load(COOKIES.splatDisplay);

  return (
    deal?.products?.length > 0 &&
    showDiscountSplat === '5' &&
    typeof window !== 'undefined' &&
    window?.location?.href.includes('/deal/')
  );
};

export const splatPriceDisplayHideNonVip = ({ deal }) => {
  const showDiscountSplat = cookies.load(COOKIES.splatDisplay);

  return !deal?.products && showDiscountSplat === '5';
};

export const splatPriceABTest = ({ deal }) => {
  const showDiscountSplat = cookies.load(COOKIES.splatDisplay);

  return (
    (deal?.products?.length > 0 && showDiscountSplat === '1') ||
    (!deal?.products &&
      (showDiscountSplat === '1' || showDiscountSplat === '2'))
  );
};

export const splatPriceDisplayDiscountAndOriginalPrice = ({ deal }) => {
  const showDiscountSplat = cookies.load(COOKIES.splatDisplay);

  return (
    (deal?.products?.length > 0 && showDiscountSplat === '3') ||
    (!deal?.products &&
      (showDiscountSplat === '3' ||
        showDiscountSplat === '4' ||
        showDiscountSplat === '5'))
  );
};

export const textOverflow = (text, maxsize = MAX_TEXT_DEAL_SIZE) => {
  if (!text) return '';
  if (text.length < maxsize) {
    return text;
  }

  return `${text.slice(0, text.lastIndexOf(' ', maxsize))}...`;
};

const getSocialCues = async (url) => {
  const response = await axios(url, {
    headers: {
      'app-platform': getAppPlatform(),
      brand: process.env.NEXT_PUBLIC_BRAND || 'wowcher',
      'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
      webapp: true,
    },
    method: 'GET',
  });

  if (response.data) {
    return response.data;
  } else {
    throw new Error(`No data received from ${url}`);
  }
};

export const useSocialCues = (dealId, disable = false) => {
  const url = dealId && !disable ? `${URLSOCIALCUE}/${dealId}` : null;
  const { data } = useSWR(url, getSocialCues, {
    dedupingInterval: 600_000,
    initialData: {},
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  return data;
};

export const getModalSocialCue = ({ selected, deal }) => {
  let productsSelected = deal.products?.map(
    ({ totalRemaining }) => totalRemaining,
  );

  if (selected >= 0) {
    productsSelected = deal.products[selected];
  }

  return getRedTextProductSocialCue(productsSelected);
};

export const showPrice = ({
  dealPrice,
  currency = 'gbp',
  hideCurrency = false,
  forceDecimals = false,
  pricePerPerson = false,
}) => {
  if (dealPrice === 0) return FREE;
  if (!dealPrice) return '';
  let price = dealPrice.toString();
  if (hideCurrency) return price;
  if (forceDecimals) {
    if (price.match(/\./g)) {
      price = `${
        dealPrice.toString().split('.')[0]
      }<span class='price__cents'>.${(
        dealPrice.toString().split('.')[1] + '0'
      ).slice(0, 2)}</span>`;
    } else if (price.match(/,/g)) {
      price = `${price.split(',')[0]}<span class='price__cents'>,${(
        price.split(',')[1] + '0'
      ).slice(0, 2)}</span>`;
    }
  }
  if (pricePerPerson) {
    price += `<span class="price__pp">PP</span>`;
  }

  return `${currency.toLowerCase() === 'gbp' ? '£' : '€'}${price}`;
};

export const hidePrice = (text, ssr) => {
  if (!ssr || !text || !text.length) return text;
  if (/([%£€])+/.test(text)) return '';

  return text;
};

export const cutArray = (array, chunkSize) => {
  if (!array || !array.length) return [];
  if (chunkSize <= 0 || chunkSize >= array.length) return [array];

  return [].concat.apply(
    [],
    array.map((element, index) => {
      return index % chunkSize ? [] : [array.slice(index, index + chunkSize)];
    }),
  );
};

export const showPerNight = ({ priceType, deal }) => {
  const isMonetaryValue = [
    SHOW_PRICE_TYPES.showPrice,
    SHOW_PRICE_TYPES.showOriginalPrice,
  ].includes(priceType);

  if (isMonetaryValue && deal?.display?.dynamicTravelDeal) return true;

  return false;
};

export const getMinPriceProduct = (deal) => {
  let minPriceProduct = null;

  for (const product of deal?.products || []) {
    if (
      !product.soldOut &&
      (minPriceProduct === null || product.price < minPriceProduct.price)
    ) {
      minPriceProduct = product;
    }
  }
  if (deal.soldOut) {
    return deal;
  } else {
    return minPriceProduct;
  }
};

export const getMinPrice = (deal) => {
  if (!deal || !deal.products) return;

  const isChooseYourProductDeal = getIsChooseYourProductDeal(deal);
  const isCYODeal = getIsChooseYourOptionsDeal(deal);
  const minPriceProduct =
    isChooseYourProductDeal || isCYODeal ? getMinPriceProduct(deal) : null;

  return minPriceProduct && !deal.soldOut ? minPriceProduct.price : deal.price;
};

export const getMinOriginalPrice = (deal) => {
  if (!deal) return null;
  if (!deal.products) return deal.originalPrice;

  const isChooseYourProductDeal = getIsChooseYourProductDeal(deal);
  const isCYODeal = getIsChooseYourOptionsDeal(deal);
  const minPriceProduct =
    isChooseYourProductDeal || isCYODeal ? getMinPriceProduct(deal) : null;

  return minPriceProduct ? minPriceProduct.originalPrice : deal.originalPrice;
};

export const getPrice = ({
  priceType,
  deal,
  forceDecimals = false,
  rowSelection,
  columnSelection,
  isVip = false,
}) => {
  const isMonetaryValue = [
    SHOW_PRICE_TYPES.showPrice,
    SHOW_PRICE_TYPES.showOriginalPrice,
  ].includes(priceType);

  if (
    splatPriceABTest({ deal }) ||
    splatPriceDisplayDiscountAndOriginalPrice({ deal })
  )
    return `${isVip ? deal?.vipDiscountPercentage : deal.discountPercentage}%`;

  const showDiscount = [SHOW_PRICE_TYPES.showDiscountIndicative].includes(
    priceType,
  );
  const isChooseYourProductDeal = getIsChooseYourProductDeal(deal);
  const isCYODeal = getIsChooseYourOptionsDeal(deal);

  if (rowSelection > 0) {
    const { currency, pricePerPerson } = deal;
    const matchingObject = deal?.products?.filter((product) =>
      isChooseYourProductDeal
        ? product.id === rowSelection
        : product.rowId === rowSelection,
    );
    if (columnSelection) {
      const matchingColumn = deal?.products?.filter(
        (product) =>
          product.columnId === columnSelection &&
          product.rowId === rowSelection,
      );

      return showPrice({
        currency,
        dealPrice:
          matchingColumn?.length > 0 ? matchingColumn[0]?.price : deal?.price,
        forceDecimals,
        pricePerPerson,
      });
    }

    return showPrice({
      currency,
      dealPrice: matchingObject[0]?.price,
      forceDecimals,
      pricePerPerson,
    });
  }
  // Splat Logic for showing the min price of available products in Multi-Product Deal
  if (isChooseYourProductDeal || isCYODeal) {
    const { currency, pricePerPerson } = deal;
    const minPrice = getMinPrice(deal);

    return showPrice({
      currency,
      dealPrice: minPrice,
      forceDecimals,
      pricePerPerson,
    });
  }
  if (isMonetaryValue) {
    const { price, currency, pricePerPerson } = deal;

    return showPrice({
      currency,
      dealPrice: price,
      forceDecimals,
      pricePerPerson,
    });
  }

  if (showDiscount && deal.display?.discountAmount && deal.discount > 0) {
    return `${formatCurrency(deal.currency, deal.discount, false, false)}`;
  } else {
    return `${deal.discountPercentage}%`;
  }
};

export const getText = ({ priceType, deal, secondCheckout, rowSelection }) => {
  if (
    splatPriceABTest({ deal }) ||
    splatPriceDisplayDiscountAndOriginalPrice({ deal })
  )
    return SAVE;

  if (
    [SHOW_PRICE_TYPES.showPrice, SHOW_PRICE_TYPES.showOriginalPrice].includes(
      priceType,
    )
  ) {
    if (
      deal?.products?.length > 1 &&
      secondCheckout &&
      arePricesDifferent(deal) &&
      !rowSelection
    ) {
      return FROM;
    } else if (deal.display?.priceText && deal.priceText && !rowSelection) {
      return deal.priceText.toUpperCase();
    } else {
      return NOW;
    }
  }

  return SAVE;
};

export const showPriceType = (
  deal,
  showDiscount = false,
  forcePrice = false,
  isHideIfNoDiscount = true,
  rowSelection,
) => {
  const price = deal?.vipPrice > 0 ? deal.vipPrice : deal.price;

  if (splatPriceABTest({ deal })) {
    if (
      (deal.priceIndicative && deal.discountPercentage) ||
      deal.display?.discountAmount
    )
      return SHOW_PRICE_TYPES.showDiscountIndicative;

    return SHOW_PRICE_TYPES.showDiscount;
  }

  if (rowSelection) {
    return SHOW_PRICE_TYPES.showDropDownPrice;
  }
  if (
    isHideIfNoDiscount &&
    showDiscount &&
    price === deal.originalPrice &&
    !deal.display?.earlyBird
  ) {
    return SHOW_PRICE_TYPES.hidden;
  }

  if (deal.display?.discount === false) {
    return SHOW_PRICE_TYPES.showPrice;
  }

  if (!showDiscount && deal.price && (deal.display?.showPrice || forcePrice)) {
    if (
      deal.originalPrice &&
      deal.display &&
      deal.display.discount &&
      deal.originalPrice !== deal.price
    ) {
      return SHOW_PRICE_TYPES.showOriginalPrice;
    }

    return SHOW_PRICE_TYPES.showPrice;
  }
  if (
    (deal.priceIndicative && deal.discountPercentage) ||
    deal.display?.discountAmount
  )
    return SHOW_PRICE_TYPES.showDiscountIndicative;

  if (deal.discountPercentage && deal.discountPercentage > 0)
    return SHOW_PRICE_TYPES.showDiscount;

  if (deal.originalPrice?.length && deal.originalPrice !== deal.price)
    return SHOW_PRICE_TYPES.showOriginalPrice;

  return SHOW_PRICE_TYPES.showPrice;
};

export const isPrice = ({
  deal,
  showDiscount = false,
  forcePrice = false,
  isHideIfNoDiscount = true,
}) => {
  const priceType = showPriceType(
    deal,
    showDiscount,
    forcePrice,
    isHideIfNoDiscount,
  );

  const showPrices =
    priceType === SHOW_PRICE_TYPES.showPrice ||
    priceType === SHOW_PRICE_TYPES.showOriginalPrice;

  return showPrices && priceType !== SHOW_PRICE_TYPES.hidden;
};

export const showPostage = (
  deal,
  showDiscount,
  forcePrice,
  isHideIfNoDiscount = true,
) => {
  if (!deal) {
    return false;
  }

  return (
    deal.deliveryType !== 'none' &&
    isPrice({ deal, forcePrice, isHideIfNoDiscount, showDiscount }) &&
    Boolean(deal.minPostagePrice)
  );
};

// postage must be shown on the second checkout
export const showPostageSecondCheckout = (deal) => {
  if (!deal) {
    return false;
  }

  return deal.deliveryType !== 'none' && Boolean(deal.minPostagePrice);
};

/** Remove the empty elements from the array. Keeps 0 */
export const getEmptyElementsRemoved = (array) => {
  return array.filter((item) => item !== null && item !== undefined);
};

/** Get the minimum or return null */
export const getMathMin = (array) => {
  if (array.length === 0) return null;

  return Math.min(...array);
};

/** Get total reminder per day based on the cap */
export const getTotalRemaining = (deal) => {
  const limitationArray = [
    deal.totalRemainingForDay,
    deal.totalRemainingForWeek,
  ];
  const array = getEmptyElementsRemoved(limitationArray);

  return getMathMin(array);
};

/** Get the product cap based on the deal cap */
export const getDealPurchaseCap = (max, remainingQuantity, quantity) => {
  if (remainingQuantity < 0) return 0;

  if (quantity) {
    return remainingQuantity + quantity <= max
      ? remainingQuantity + quantity
      : quantity;
  } else {
    return Math.min(remainingQuantity, max);
  }
};

/** Get the product cap for a specific product inside a deal */
export const getProductPurchaseCap = (deal, index = 0) => {
  if (!deal || !deal.products || !deal.products[index]) {
    return 0;
  }
  const product = deal.products[index];

  const limitationArray = [
    deal.totalRemainingForDay,
    deal.totalRemainingForWeek,
    deal.purchaseCap,
    product.totalRemaining,
    product.purchaseCap,
    product.totalRemainingForDay,
    product.totalRemainingForWeek,
    product.maxQuantityUserCanCurrentlyBuy,
  ];
  const array = getEmptyElementsRemoved(limitationArray);

  return getMathMin(array);
};

const getIsExpressBuyForUser = async (url) => {
  const response = await axios(url, {
    headers: httpCommonHeaders(),
    method: 'GET',
    withCredentials: true,
  });

  return response?.data?.data ?? false;
};

export const useIsExpressBuy = (dealId, isAuthenticated) => {
  const url = `${URLPURCHASEVIEW}/${dealId}`;
  const { data } = useSWR(
    isAuthenticated ? url : null,
    getIsExpressBuyForUser,
    {
      initialData: false,
    },
  );

  if (isAuthenticated && data) {
    return data;
  } else {
    return false;
  }
};

export const formatNames = (pdtName) => {
  return pdtName
    ?.toLowerCase()
    ?.replace(/[^\da-z]/g, '-')
    ?.replace(/-+/g, '-')
    ?.replace(/^-|-$/g, '');
};
// Check if the value is not null or undefined (can be 0)
export const isExist = (value) => {
  return value !== null && value !== undefined;
};

// Check if the deal is already in basket
export const isDealAlreadyInBasket = (dealId, basketProducts = []) => {
  if (basketProducts.length === 0) return false;
  const products = basketProducts.filter((item) => item.dealId === dealId);
  const quantityArray = products.map((item) => item.quantity);

  return quantityArray.reduce((a, b) => a + b, 0);
};

// if the address bar url ends 'electricals/accessories/25619106'
// should redirect to the canonical e.g. 'electricals/accessories/25619106/description'
export const isRedirectNumberLastInSlug = (slug, canonical) => {
  const canonicalSlug = canonical?.split('/');
  if (!canonicalSlug) return false;

  let lastCanonicalItem = canonicalSlug[canonicalSlug.length - 1];
  if (!lastCanonicalItem)
    lastCanonicalItem = canonicalSlug[canonicalSlug.length - 2];
  const lastSlugItem = slug[slug.length - 1];

  // slug ends with number and canonical ends with text as expected
  if (!isNaN(lastSlugItem) && isNaN(lastCanonicalItem)) return true;

  return false;
};

export const isRedirectDeal = (mainDeal, routerPath, productId) => {
  const mainDealUrl = mainDeal?.urlPath;
  const mainDealLocation = mainDealUrl
    ?.split('/')
    .filter((item) => item.length);

  // Strip Next.js json calls / paths
  const path = routerPath.includes('/_next/data')
    ? routerPath.replace(/_next\/data\/.+?\/|\.json/g, '')
    : routerPath;

  const routerLocation = path?.split('/').filter((item) => item.length);

  const { details } = parseWowcherPath(routerPath);

  if (
    details?.locationType === DEAL_LOCATION_TYPES.local &&
    !details?.isEvergreen &&
    mainDealLocation &&
    routerLocation &&
    mainDealLocation[1] !== routerLocation[1] &&
    !productId
  ) {
    return true;
  }

  return false;
};

// Various deal condition layout for travel based on component type
// If we notice there are multiple scenarios like this move this into its own util
export const displayDealLayoutComponentTravel = (
  typeComponent,
  deal,
  pageDetails,
  isTypeMobile,
) => {
  switch (typeComponent) {
    case 'MainDealCombinedGiftButton':
      // if the page is not a travel page render default
      if (!pageDetails?.isTravel) {
        return true;
      }
      // if this is a default deal and is not mobile
      if (pageDetails?.isTravel && getIsDefaultDeal(deal) && !isTypeMobile) {
        return true;
      }
      // travel page and mobile view
      // travel page layout should work mostly the same as the default
      if (pageDetails?.isTravel && isTypeMobile) {
        return true;
      }
      break;
    default:
      return false;
  }
};

/** how many thumbs to show on the main deal */
export const getMainDealThumbCountFromBreakpoint = (breakpoint) => {
  switch (breakpoint) {
    case 'sm':
      return 4;
    case 'md':
    case 'lg':
      return 5;
    case 'xl':
      return 6;
    default:
      return 6;
  }
};

export const isDealImagesCountLessThan = function (deal, numberLess) {
  if (deal?.images?.length < numberLess) {
    return true;
  }

  return false;
};

export const getIsDropdownDeal = (deal) => {
  return deal?.productDisplay?.type === 'dropdown';
};

export const getIsLocalDeal = (deal) => {
  return deal.category && deal.category.canonicalPathType !== 'national';
};

export const getIsNationalDeal = (deal) => {
  return deal.category?.canonicalPathType === 'national';
};

export const getIsPaypalFeatured = (deal) => {
  if (!deal || !deal.products) {
    return false;
  }

  return deal.products.filter((product) => product.totalPrice >= 30).length > 0;
};

export const getSuffix = (path, promoTitle) => {
  const getQuerySymbol = path?.includes('?') ? '&' : '?';

  if (promoTitle === DEALS_PROMO_TYPES().recommended.title)
    return `${getQuerySymbol}usr_src=recommended`;
  if (promoTitle === DEALS_PROMO_TYPES().recentlyViewed.title)
    return `${getQuerySymbol}usr_src=recentlyViewed`;

  return '';
};

const specialPageDealUrl = ({ deal, location, source }) => {
  const { details, searchParams } = parseWowcherPath(deal.urlPath);
  let path = deal.urlPath;
  if (deal.evergreenUrl) {
    path = deal.evergreenUrl;
  } else if (details.locationType === DEAL_LOCATION_TYPES.local) {
    path = deal.urlPath;
  }

  const query = new URLSearchParams();
  if (source || searchParams.has('usr_src')) {
    query.set('usr_src', searchParams.get('usr_src') || source);
  }
  const queryString = query.toString();

  return `${path}${queryString ? '?' + queryString : ''}`;
};

export const getUrlForCheckoutDeal = ({ deal, path: defaultPath }) => {
  const isNationalDeal = getIsNationalDeal(deal);
  const isLocalDeal = getIsLocalDeal(deal);
  let path = defaultPath;

  if (isNationalDeal) {
    path = path.replace(/^\/deal\/[\w-]+\//, `/deal/shop/`);
  }

  if (isLocalDeal) {
    const userLocation = getUserLocationShortName();
    path = path.replace(/^\/deal\/[\w-]+\//, `/deal/${userLocation}/`);
  }

  if (deal?.category?.canonicalPathType === PAGE_TYPES.travel) {
    path = path.replace(
      /^\/deal\/[\w-]+\//,
      `/deal/${isWowcherBrand() ? 'travel' : 'escapes'}/`,
    );
  }

  return path;
};

export const getUrlFromDeal = ({ deal = {}, originPath, location }) => {
  const { dealPrefix, id, urlPath, shareUrl, evergreenUrl, promoType } = deal;
  const { pathArray, pageType, details } = parseWowcherPath(originPath);
  const [, pathLocation, ...rest] = pathArray;

  switch (pageType) {
    case PAGE_TYPES.claimExperienceGift: {
      const dealPath = specialPageDealUrl({
        deal,
        location,
        source: null,
      });

      return `${dealPath}?${PAGE_QUERY.claimMyGift}=true`;
    }

    case PAGE_TYPES.guide:
    case PAGE_TYPES.giftFinder:
      return specialPageDealUrl({
        deal,
        location,
        source: null,
      });

    case PAGE_TYPES.special:
      return specialPageDealUrl({
        deal,
        location: details.location || 'shop',
        source: slugAsSource(details.slug),
      });

    case PAGE_TYPES.recentlyViewed:
    case PAGE_TYPES.recommended:
    case PAGE_TYPES.search:
    case PAGE_TYPES.mapView:
      return specialPageDealUrl({
        deal,
        location,
        source: pageType,
      });
  }

  let path;
  const isTravelDeal = Boolean(
    deal?.urlPath?.includes('deal/travel') ||
      deal?.urlPath?.includes('deal/escapes'),
  );

  let dealLocation = pathLocation;
  if (isTravelDeal) {
    dealLocation = isWowcherBrand() ? 'travel' : 'escapes';
  }

  if (evergreenUrl) {
    path = stripOrigin(evergreenUrl);
  } else if (pageType === PAGE_TYPES.deal) {
    // find dealId or the letter e (evergreen) in slug
    const sliceIndex = pathArray.findIndex((item) => /^(\d+|e)$/.test(item));
    if (sliceIndex > -1) {
      // if it's a travel deal but not on a travel page we don't include cats/subcats
      if (isTravelDeal || location === PAGE_TYPES.checkout) {
        const restOfUrl =
          isTravelDeal && !details.isTravel
            ? []
            : pathArray.slice(2, sliceIndex);
        path = '/' + ['deal', dealLocation, ...restOfUrl, id].join('/');
        if (dealPrefix?.length) path += `/${dealPrefix}`;
        if (location === PAGE_TYPES.checkout) {
          path = getUrlForCheckoutDeal({ deal, path });
        }
      } else {
        path = urlPath;
      }
    }
  } else if (
    pageType === PAGE_TYPES.category &&
    !details.isHyperlocal &&
    isTravelDeal
  ) {
    path = urlPath.replace('travel', dealLocation);
  }

  // If path is not defined by this time, then just some fallback path
  if (!path) {
    path = urlPath || shareUrl || '/';
  }

  if (promoType?.title) {
    path += getSuffix(path, promoType.title);
  }

  return path;
};

export const addressToPinData = (name, address) => {
  return {
    body: address ? (
      <div>
        {address.addressLine1 && <p>{address.addressLine1}</p>}
        {address.addressLine2 && <p>{address.addressLine2}</p>}
        {address.town && <p>{address.town}</p>}
        {address.postCode && <p>{address.postCode}</p>}
      </div>
    ) : null,
    lat: address.latLon ? address.latLon.lat : 51.505,
    lon: address.latLon ? address.latLon.lon : -0.090_1,
    title: name || 'Location',
  };
};

const getBundlePins = (deal) => {
  const out = [];
  deal.bundle.children.forEach((child) => {
    child.locations.forEach((loc) => {
      out.push(addressToPinData(child.businessName, loc));
    });
  });

  return out;
};

const getNonBundlePins = (deal) => {
  if (!deal || !deal.business) {
    return [];
  }

  if (deal.business.otherAddresses && deal.business.otherAddresses.length > 0) {
    return deal.business.otherAddresses.map((address) => {
      return addressToPinData(deal.business.name, address);
    });
  }

  if (deal.business.mainAddress) {
    return [addressToPinData(deal.business.name, deal.business.mainAddress)];
  }

  return [];
};

export const getPins = (deal) => {
  if (!deal || (deal && deal.redeemLocationUrl)) {
    return [];
  }

  // bundle deals work slighty differently
  if (deal.bundle) {
    return getBundlePins(deal);
  } else {
    return getNonBundlePins(deal);
  }
};

export const usePins = (deal) => {
  return useMemo(() => getPins(deal), [deal]);
};

export const getShowReviews = (deal, isTravel) => {
  const minReviewsAvg = showNationalReviews() ? 4 : MIN_REVIEW_AVERAGE;
  if (isTravel && !SHOW_TRAVEL_REVIEWS[process.env.NEXT_PUBLIC_SITE]) {
    return false;
  }

  if (
    !isTravel &&
    (!SHOW_REVIEWS[process.env.NEXT_PUBLIC_SITE] || !showNationalReviews())
  ) {
    return false;
  }

  return (
    deal?.reviews?.length > 0 && deal?.reviewSummary?.average >= minReviewsAvg
  );
};

/** Does the source allow for a deal to be bought in the grace period (ie. passed closed date but deal.open) */
export const sourceAllowsGracePeriodPurchase = (query) => {
  if (!query) {
    return false;
  }

  const allowList = ['search', 'recentlyViewed', 'recommended'];

  return (
    query.st_cid !== undefined ||
    (query.usr_src && query.usr_src.startsWith('sponsored_search_')) ||
    allowList.includes(query.usr_src)
  );
};

export const getIsOpen = (deal, query) => {
  // 1. non-open deals are closed.
  if (!deal || !deal.open) {
    return false;
  }

  // 2. if deal is open but after the close date: check if deal allows grace period
  // and if so check that the user has come from a permissable source (e.g email/search)
  if (dayjs() > dayjs(deal.closingDate).endOf('day')) {
    return (
      deal.display &&
      deal.display.lastChance &&
      sourceAllowsGracePeriodPurchase(query)
    );
  }

  // 3. The deal is open.
  return true;
};

const getSingleCompanyVatInfo = (deal) => {
  const displayName =
    deal && deal.business
      ? deal.business.displayName || deal.business.name || null
      : null;
  let vatNumber =
    deal.products && deal.products[0] && deal.products[0].merchant
      ? deal.products[0].merchant.vatNumber
      : null;

  if (!vatNumber) {
    vatNumber =
      deal.products &&
      deal.products.merchant &&
      deal.products.merchant.vatNumber
        ? deal.products.merchant.vatNumber
        : null;
  }

  return { displayName, vatNumber };
};

export const getVatInfo = (deal) => {
  if (!deal) {
    return [];
  }

  if (!deal.bundle || !deal.bundle.children || !deal.bundle.children.length) {
    return [getSingleCompanyVatInfo(deal)];
  }

  return deal.bundle.children.map((item) => {
    return {
      displayName: item.businessName || item.name || '',
      vatNumber: item.vatNumber || null,
    };
  });
};

export const getEvergreenPageInfo = async ({
  location,
  category,
  subCategory,
  dealName,
}) => {
  const { data } = await axios({
    method: 'GET',
    params: {
      brand: process.env.NEXT_PUBLIC_BRAND,
    },
    url: `${URLEVERGREEN}/${location}/${category}/${subCategory}/${dealName}`,
  });

  return data;
};

export const isAdminFeeDeal = (deal) => {
  const products = deal?.products;
  if (!products) {
    return false;
  }

  return products.some((product) => {
    return product.adminFee !== 0;
  });
};

export const isClosedDeal = (deal, query, pathname = '', isPreview) => {
  if (!deal) {
    return true;
  }
  if (isPreview) {
    return false;
  }

  const currentDate = new Date();
  const closingDate = new Date(deal.closingDate);
  const timeDifference = currentDate.getTime() - closingDate.getTime();
  const dayDifference = timeDifference / (1_000 * 3_600 * 24);

  const availableProducts = deal.products?.filter((product) => {
    const limitReached =
      product.dailyPurchaseCapReached || product.weeklyPurchaseCapReached;

    return !limitReached;
  });

  const defaultDealPurchaseCap =
    deal.dailyPurchaseCapReached ||
    deal.weeklyPurchaseCapReached ||
    availableProducts?.length === 0;

  if (currentDate > closingDate && deal.display?.lastChance) {
    const canBuyClosedDeal =
      query.usr_src?.startsWith('sponsored_search_') ||
      ['search', 'recommended', 'recentlyViewed'].includes(query.usr_src) ||
      pathname?.includes('email-deals') ||
      query.st_cid;

    return !canBuyClosedDeal;
  }

  const isClosedLongTimeAgo = deal.enableBuyClosedDeal && dayDifference > 4;

  return (
    deal.soldOut || defaultDealPurchaseCap || !deal.open || isClosedLongTimeAgo
  );
};

export const sendDealImpressions = (impressions, userLocation) => {
  if (!userLocation) {
    throw new Error('Deal impressions: undefined or missing location data');
  }
  try {
    const data = Array.from(impressions);

    trackEvent('send_deal_impressions', data);

    // controlled by third party scrips which reads the data set here
    // third party scripts clears the batch
    // this is being used because of race conditions and how the batch is being cleared and we dont when they call this.
    pointContinuousTrackingData('copyDealImpressions', data);

    // Note: these calls are "best effort", we're not waiting for response
    axios({
      data,
      headers: httpCommonHeaders(),
      method: 'POST',
      url: URLDEALIMPRESSION.replace('##LOCATION##', userLocation),
    });
    impressions.clear();
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line func-style
export function GenerateUseDealImpresssion() {
  const impressions = new Set();
  const batchRequest = 50;
  // data accessible on the window object for the Marketing Team in DTM by typing
  // `window.continuousTrackingData.dealImpressions;`
  pointContinuousTrackingData('dealImpressions', impressions);

  let userLocation;

  if (typeof window !== 'undefined') {
    window.addEventListener('beforeunload', () => {
      if (window.location.href.includes('subsubcat')) return;

      sendDealImpressions(impressions, userLocation || 'national');
    });
  }

  return ({ dealId, componentRef, location }) => {
    const observerRef = useRef();

    if (!userLocation) userLocation = location;
    useClientSideEffect(() => {
      if (!dealId || !componentRef.current) {
        return;
      }

      const observer = new IntersectionObserver(
        (observerEntry) => {
          if (observerEntry[0].isIntersecting) {
            impressions.add(dealId);
            if (impressions.size >= batchRequest)
              sendDealImpressions(impressions, userLocation);
          }
        },
        {
          root: null,
          threshold: 1,
        },
      );

      observer.observe(componentRef.current);

      return () => observer.disconnect();
    }, [observerRef, dealId]);
  };
}

export const useDealImpresssion = new GenerateUseDealImpresssion();

export const isIELocation = (url) => {
  const parts = url.split('?')[0].split('/');
  const ieLocations = [
    'cork',
    'dublin',
    'galway',
    'limerick',
    'shop',
    'southeastireland',
    'travel',
    'vip',
  ];

  return ieLocations.includes(parts[2].toLowerCase());
};
