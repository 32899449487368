/* eslint-disable no-console, id-match, complexity, sonarjs/cognitive-complexity, fp/no-delete */
import dayjs from 'dayjs';
import merge from 'lodash/merge';
import Cookies from 'react-cookies';
import { PAYMENT_METHODS } from '../config/constants/payment';
import COOKIES from '../config/cookies/cookies';
import ErrorTrackingService from './errorTrackingService';

/**
 * Page tracking
 *
 * @param {object} pageProps additional properties from controller to be merged with `DTM`
 */

const TRAVEL_TYPE = null;

let dtmPromise;
export const waitForDtm = () => {
  if (!dtmPromise) {
    dtmPromise = new Promise((resolve) => {
      // Don't run server side
      if (typeof window === 'undefined') {
        resolve(false);

        return;
      }

      if (typeof window.adobe !== 'undefined') {
        resolve(true);

        return;
      }

      const CHECK_INTERVAL_MS = 300;
      const MAX_TRY_COUNT = 40;
      let tryCount = 0;
      const interval = setInterval(() => {
        tryCount++;
        if (typeof window.adobe !== 'undefined') {
          clearInterval(interval);
          console.log('🚀 🚀 [DTM] is now available 🚀 🚀');
          resolve(true);
        } else if (tryCount > MAX_TRY_COUNT) {
          clearInterval(interval);
          console.warn(
            "🚀 🚀 [DTM] hasn't loaded correctly, sorry DTM is unavailable 🚀 🚀",
          );
          ErrorTrackingService.logError({
            message: `DTM has not loaded correctly`,
            name: `DTM issue`,
          });
          resolve(false);
        }
      }, CHECK_INTERVAL_MS);
    });
  }

  return dtmPromise;
};

const _newDOD = () => {
  const data = {};
  if (window.dod?.persistent) {
    data.persistent = window.dod.persistent;
  }

  return data;
};

const _directCall = async (eventName) => {
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;

  if (typeof window._satellite === 'object') {
    window._satellite.track(`RE_${eventName}`);
  } else {
    setTimeout(() => {
      if (typeof window._satellite === 'object') {
        window._satellite.track(`RE_${eventName}`);
      } else {
        console.error('Launch was never ready');
      }
    }, 1_500);
  }
};

// checkNonIABVendor function
function checkNonIABVendor(vendorName) {
  if (localStorage.getItem('cookieConsentDenied')) {
    const consentDenied = localStorage
      .getItem('cookieConsentDenied')
      .split(',');

    return (
      !consentDenied.includes('c:' + vendorName) &&
      !consentDenied.includes(vendorName)
    );
  } else {
    return true;
  }
}

export const trackZarazEvent = async (
  eventName,
  parameters = {},
  ec = false,
) => {
  if (!window.zaraz) {
    console.log('Zaraz not loaded properly');

    return;
  }
  if (ec) {
    window.zaraz.ecommerce(eventName, parameters);
  } else {
    window.zaraz.track(eventName, parameters);
  }
};

export const trackAllZarazEvents = (eventName, dod) => {
  let urlParameters = '';
  let fbc;
  const userProfile = Cookies.load(COOKIES.userProfile);
  if (dod) {
    urlParameters = new URL(dod.url).searchParams;
    fbc = Cookies.load(COOKIES.fbc) || urlParameters.get('fbclid');
  }

  if (eventName === 'click_buy') {
    trackZarazEvent('clickBuy', {
      brand: dod.dealData.business.name,
      category: dod.dealData.category.name,
      id: dod.dealData.id,
      name: dod.dealData.headline,
      option: 'clicBuy',
      step: 1,
    });
  }

  if (eventName === 'newEmailEvent') {
    trackZarazEvent('Lead', {
      em: userProfile?.email || null,
      fbc: fbc || null,
      fn: userProfile?.firstName || null,
      ln: userProfile?.lastName || null,
    });
  }

  if (eventName === 'completed_purchase') {
    const products = [];
    window.dod.products.split(',').forEach(function (e) {
      const product_info = {
        price: e.split(';')[3],
        product_id: e.split(';')[1],
        quantity: e.split(';')[2],
      };
      products.push(product_info);
    });

    trackZarazEvent('Purchase', {
      content_ids: window.dod.eVar49.split(','),
      content_type: 'product',
      currency: 'GBP',
      em: userProfile?.email || null,
      fbc: fbc || null,
      fn: userProfile?.firstName || null,
      ln: userProfile?.lastName || null,
      value: window.dod.orderValue,
    });
    trackZarazEvent(
      'Order Completed',
      {
        order_id: window.dod.purchaseID,
        products,
        revenue: window.dod.orderValue,
      },
      true,
    );
  }

  // Fire the 'PageView_test2' event when trackEvent('lightbox_appear') is called and the following conditions are met
  if (
    eventName === 'lightbox_appear' &&
    (urlParameters
      .get('ito')
      .includes('Wowcher_PaidSocial_GB_Facebook_TTH_ShoppingAds_') ||
      urlParameters
        .get('ito')
        .includes('Wowcher_PaidSocial_TTH_Facebook_ShoppingAds_'))
  ) {
    trackZarazEvent('PageView_test2');
  }
};

/**
 * Track a page that was in secure-wowcher angular site
 *
 * @param {object} pageProps additional properties from controller
 */
export const trackSecurePage = async (pageProps = {}) => {
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;

  const dod = _newDOD();

  window.dod = merge(dod, pageProps);
  window.dod.url = window.location.href;
  const urlParameters = new URL(window.dod.url).searchParams;
  // FB Tracking Update
  const fbc = urlParameters.get('fbclid') || Cookies.load(COOKIES.fbc);
  const userProfile = Cookies.load(COOKIES.userProfile) || {};

  if (dod?.dealId?.length > 0) {
    let basketDeals = dod?.dealId?.split(',');
    basketDeals = dod?.dealData;

    // Fire the 'Checkout Step Viewed' event when a page is viewed and the following conditions are met
    // Note that the code loops through the basket items
    if (dod?.url?.includes('/checkout')) {
      basketDeals?.forEach(function (e) {
        trackZarazEvent('checkout', {
          brand: e.business.name,
          category: e.category.name,
          id: e.id,
          name: e.headline,
          option: 'checkout',
          price: e.price,
          step: 2,
        });
      });
    }

    // Fire the 'AddToCart' event when a page is viewed and the following conditions are met
    if (dod?.url?.includes('/checkout')) {
      trackZarazEvent('AddToCart', {
        content_ids: dod?.dealId?.split(','),
        content_name: 'Shopping Cart',
        content_type: 'product',
        currency: 'GBP',
        em: userProfile?.email || null,
        fbc: fbc || null,
        fn: userProfile?.firstName || null,
        ln: userProfile?.lastName || null,
        value: dod?.orderValue,
      });
    }
  }
  _directCall('secure_page_view');
};

const getPaymentMethod = (type) => {
  if (type === PAYMENT_METHODS.paypal) return 'PayPal';
  if (type === PAYMENT_METHODS.payPalCredit) return 'PayPal Credit';
  if (type === PAYMENT_METHODS.googlePay) return 'Google Pay';
  if (type === PAYMENT_METHODS.applePay) return 'Apple Pay';
  if (type === PAYMENT_METHODS.klarna) return 'Klarna';
  if (type === PAYMENT_METHODS.clearpay) return 'Clearpay';

  return 'Card';
};

export const trackPage = async (pageProps = {}, pageName = '') => {
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;

  const dod = _newDOD();
  const StoreLocation = pageProps.location || '';
  const urlParameters = new URL(window.location.href).searchParams;
  // FB Tracking Update
  const fbc = urlParameters.get('fbc') || Cookies.load(COOKIES.fbc);
  const userProfile = Cookies.load(COOKIES.userProfile) || {};
  pageProps = pageProps || {};

  pageProps.category = pageProps?.dealData?.category?.shortName || null;
  pageProps.subcategory = pageProps?.dealData?.subCategory?.shortName || null;
  dod.section1 = 'deals';
  dod.location = StoreLocation.shortName;
  dod.siteLocation = StoreLocation.name;
  dod.scrollDepth = pageProps?.scrollDepth || 1;
  dod.url = location.href;
  dod.searchTerms = pageProps?.searchTerms || '';
  dod.searchResults = pageProps?.searchResults || '';
  dod.sponsoredSearch = pageProps?.sponsoredSearch || '';
  dod.categories = pageProps?.categories || null;
  dod.filter = pageProps?.filter || null;
  dod.price = pageProps.dealData?.price || null;
  dod.sort = pageProps?.sort || null;
  dod.noOfSimilarDeals = pageProps?.noOfSimilarDeals || null;
  dod.noOfPersonalisedDeals = pageProps?.noOfPersonalisedDeals || null;

  if (pageName) {
    dod.section2 = `deals: ${pageName}`;
    dod.pageName = `deals: ${pageName}`;
  } else {
    const location = pageProps?.dealLocation || StoreLocation.shortName;
    let category = pageProps?.category || 'local';

    if (location === TRAVEL_TYPE && !pageProps?.category) category = 'main';

    if (pageProps?.dealId) dod.dealId = pageProps.dealId;

    dod.section2 = `deals: ${location}`;
    dod.section3 = `${dod.section2}: ${category}`;

    if (pageProps?.subcategory)
      dod.section3 = `${dod.section3}: ${pageProps.subcategory}`;

    dod.pageName = `${dod.section3}: default deal with list`;
  }

  if (pageName.includes('welcome')) {
    dod.pageName = `${pageName}`;
    dod.section1 = 'welcome';
    dod.section2 = '';
  }

  if (pageName.includes('my account: profile')) {
    dod.section1 = 'my account';
    dod.section2 = 'my account: profile';
  }

  const pageViewEvents = JSON.parse(localStorage.getItem('page_view_event'));

  if (pageViewEvents) {
    dod.dealClickedPosition = pageViewEvents?.dealClickedPosition || null;
    dod.dealViewType = pageViewEvents?.dealViewType || null;
  }

  pageProps.location = StoreLocation.shortName;

  window.dod = merge(dod, pageProps);

  // Fire the 'Product Viewed' event when a page is viewed and the following conditions are met
  if (
    checkNonIABVendor('google') &&
    (dod?.url.includes('/deal/') || dod?.url.includes('/email-deals/')) &&
    dod?.dealId > 0
  ) {
    trackZarazEvent(
      'Product Viewed',
      {
        brand: dod.dealData.business.name,
        category: dod.dealData.category.name,
        dimension1: 'testDimension<>Ecom',
        id: dod.dealId,
        name: dod.dealData.headline,
        position: 1,
        product_id: dod.dealId,
      },
      true,
    );
  }

  // Fire the 'ViewContent' event when a page is viewed
  trackZarazEvent('ViewContent', {
    content_ids: [dod.dealId],
    content_type: 'product',
    em: userProfile?.email || null,
    fbc: fbc || null,
    fn: userProfile?.firstName || null,
    ln: userProfile?.lastName || null,
  });

  _directCall('page_view');
};

export const updatePersistentProperty = async (propertyName, propertyValue) => {
  const dtmAvailable = await waitForDtm();

  if (!dtmAvailable) {
    console.warn('DTM not available');

    return;
  }

  if (!propertyName) return;
  if (!window.dod) window.dod = {};
  if (!window.dod.persistent) window.dod.persistent = {};

  // delete property if no propValue given
  if (
    window.dod.persistent &&
    typeof propertyValue === typeof undefined &&
    window.dod.persistent[propertyName]
  ) {
    delete window.dod.persistent[propertyName];

    return;
  }

  window.dod.persistent[propertyName] = propertyValue;
};

export const trackError = async (pageProps = {}) => {
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;

  const dod = _newDOD();
  window.dod = merge(dod, pageProps);
  _directCall('error_view');
};

export const trackEvent = async (eventName, parameters = {}) => {
  console.log('Event Called -> ', eventName);
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;

  if (Object.keys(parameters).length > 0) {
    window.dodEvents = parameters;
  } else {
    delete window.dodEvents;
  }

  trackAllZarazEvents(eventName, window.dod);

  console.log('DOD ->', window.dod);
  _directCall(eventName);
};

export const trackUnbxdAddToCart = (id, quantity, productId) => {
  const payload = {
    pid: String(id),
    qty: String(quantity),
    variantId: String(productId),
  };

  if (window.Unbxd && typeof window.Unbxd.track === 'function') {
    window.Unbxd.track('addToCart', payload);
  } else {
    console.error('unbxdAnalytics.js is not loaded!');
  }
};

export const trackUnbxdOrderEvent = (CheckoutOrder) => {
  const unxPayload = CheckoutOrder.products.map((product) => {
    return {
      pid: String(product.dealId),
      price: String(product.price),
      qty: String(product.quantity),
    };
  });
  function fireUnbxdOrder() {
    setTimeout(function () {
      if (window.Unbxd) {
        window.Unbxd.trackMultiple('order', unxPayload);
      } else {
        console.log('Unbxd is not defined');
      }
    }, 2_000);
  }

  fireUnbxdOrder();
};
export const pointContinuousTrackingData = async (parameterName, reference) => {
  try {
    const dtmAvailable = await waitForDtm();
    if (!dtmAvailable) return;

    if (!window.continuousTrackingData) {
      window.continuousTrackingData = {};
    }

    window.continuousTrackingData[parameterName] = reference;
  } catch {
    console.log(`${parameterName} tracking not set`);
  }
};

export const updateDod = async (props) => {
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;

  const dod = window.dod;
  const propertyData = props || {};
  window.dod = merge(dod, propertyData);
};

const _hoursUntilClosingDate = (isExpressBuy, products) => {
  return isExpressBuy
    ? ''
    : products
        .map((product) => {
          return product.closingDate
            ? dayjs(product.closingDate).diff(dayjs(), 'hour')
            : '';
        })
        .join();
};

const getProducts = (products) => {
  return products
    .map(
      (product) =>
        `;${product.dealId};${product.quantity ? product.quantity : 0};${
          product.finalDealOrderAmount
        }`,
    )
    .join();
};

const _serializedProductInformation = ({
  isExpressBuy,
  isPostCheckout = false,
  order,
  products,
  purchasePrice,
}) => {
  if (isPostCheckout) {
    return `;${
      products[0].dealVoucherId ||
      products[0].dealId ||
      products[0].dealVoucherProductId
    };${order.data.quantity};${purchasePrice}`;
  }

  return isExpressBuy
    ? `;${products[0].dealId};${order.data.quantity};${purchasePrice}`
    : getProducts(products);
};

const _createDod = async (trackingData) => {
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;

  if (trackingData && !trackingData.paymentMethod) {
    trackingData.paymentMethod = 'Card';
  }
  window.dod = merge({}, trackingData);
};

/**
 * Creates new dod with specific tracking data for purchase tracking and fires a "RR_completed_purchase" event
 */
export const trackPurchase = (
  products,
  order,
  isExpressBuy,
  ancilliaryType = null,
  isPostCheckout = false,
  vipOptInType = '',
) => {
  if (!products || !order) return;
  try {
    // Formatting data
    const purchase = JSON.parse(order.config.data);
    let purchaseContainsGift = null;
    if (!isPostCheckout) {
      purchaseContainsGift = purchase.orderLines
        .map((orderLine) => orderLine.gift)
        .includes(true);
    }
    const paymentMethod =
      order.data.walletAmountUsed === 0
        ? order.data.cardType
        : 'Wallet, ' + order.data.cardType;
    const paymentType = order.data.cardType;
    const purchaseTypeField = isExpressBuy ? 'oneclick' : 'normal';
    // deal fields comma separated
    const dealClosingDateField = _hoursUntilClosingDate(isExpressBuy, products);
    const dealIdField = isPostCheckout
      ? products[0].dealVoucherId
      : isExpressBuy
      ? products[0].dealId
      : products.map((product) => product.dealId).join();

    // Compute total value of purchase excluding wallet credits
    let purchasePrice;
    if (purchase.walletUsed) {
      purchasePrice = purchase.cashAmount + order.data.walletAmountUsed;
    } else {
      purchasePrice = purchase.cashAmount;
    }
    const productsField = _serializedProductInformation({
      isExpressBuy,
      isPostCheckout,
      order,
      products,
      purchasePrice,
    });

    let secondCheckoutPageName = 'my account: my vouchers: order confirmation';
    if (
      products[0]?.addonType === 'VVE' ||
      products[0]?.title === 'One year of VIP'
    ) {
      secondCheckoutPageName = 'second-checkout: benefits';
    } else if (products[0]?.addonType === 'DVP') {
      secondCheckoutPageName = 'second-checkout: deals';
    }
    // Building tracking data object
    const trackingData = {
      ancilliaryType: ancilliaryType || null,
      // not considering wallet usage
      currencyCode: order.data.currency.toLowerCase(),

      // vve  dealId
      dealId: products[0]?.dealVoucherId || null,

      // deal Price
      dealPrice: products[0]?.price || null,

      // cash amount from purchase not considering wallet usage
      eVar4: purchaseTypeField,

      // 4 Purchase Type
      eVar27: purchaseContainsGift ? 'gift' : 'normal',

      // 27 Buy/Bought as a Gift
      eVar38: order.data.productOrder.id,

      // 38 Product Order ID
      eVar42: paymentMethod,

      // 42 Payment Method
      eVar43: order.data.walletAmountUsed,

      // 43 Wallet Credit Used
      eVar45: products.length,

      // 45 Number of Deals Bought
      eVar48: dealClosingDateField,

      // 48 Deal Time Remaining
      eVar49: dealIdField,

      // 49 Deal ID
      eVar51: getPaymentMethod(paymentType),

      orderValue: purchasePrice,

      pageName: secondCheckoutPageName,

      productId: isPostCheckout
        ? products[0]?.dealProductId || products[0]?.dealVoucherProductId
        : null,

      products: productsField,
      // 51 Payment Type
      purchaseID: order.data.productOrder.id,
      url:
        window.location.protocol +
        '//' +
        window.location.host +
        '/myaccount/vouchers',
      // vip dealId
      vipDealId: products[0]?.dealId,
    };
    _createDod(trackingData);
    if (isPostCheckout) {
      trackEvent('ancilliary_payment_complete');
    } else {
      trackEvent('completed_purchase');
      if (vipOptInType) {
        trackEvent(vipOptInType);
      }
    }
  } catch (error) {
    ErrorTrackingService.logError(error);
  }
};

export const sendAdobeAnalyticsData = async (eVar118Value) => {
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;
  const s = window.s || {};
  s.linkTrackVars += ',eVar118';
  s.eVar118 = eVar118Value;
  s.tl(true, 'o', 'user location update');
};

export const updateTrackingLocation = (newLocation, reason) => {
  if (reason !== '') {
    const eVar118Value = `${newLocation};${reason}`;
    sendAdobeAnalyticsData(eVar118Value);
  }
};

export const getSecondLetter = (string) => {
  if (string.length >= 2) {
    return string.charAt(1);
  }

  return null;
};

export const getFirstLetter = (string) => {
  if (string.length >= 2) {
    return string.charAt(0);
  }

  return null;
};

export const isOspValid = () => {
  const v5_ab = Cookies.load(COOKIES.v5_ab);

  let firstLetter = null;
  let secondLetter = null;

  // Check if the cookie exists and has at least two characters
  if (v5_ab && v5_ab.length >= 2) {
    firstLetter = getFirstLetter(v5_ab);
    secondLetter = getSecondLetter(v5_ab);
  }

  const osp = secondLetter === 'B' || secondLetter === 'C';
  const similarDeal = firstLetter === 'B';

  return similarDeal || osp;
};

export const initializeTracking = (deal = {}, pageTrackingData) => {
  if (isOspValid()) {
    if (!Array.isArray(pageTrackingData) || pageTrackingData.length === 0)
      return;
    let dealViewType = deal?.isSimilarRecommendedDeal
      ? 'similar'
      : deal?.isPersonalisedRecommendedDeal
      ? 'personalised'
      : 'other';
    let dealClickedPosition =
      pageTrackingData.findIndex((x) => x?.id === deal?.id) + 1;

    // following condition will be true in case deal is mainDeal coming from Cat/SubCat/Shopping/Local/Travel
    if (
      deal &&
      dealClickedPosition === 0 &&
      !Object.prototype.hasOwnProperty.call(deal, 'isSimilarRecommendedDeal')
    )
      dealClickedPosition = 1;

    if (dealViewType === 'similar' && deal?.recAlgo) {
      dealViewType = `similar: ${deal.recAlgo}`;
    }

    if (dealClickedPosition) {
      const dodObject = {
        dealClickedPosition,
        dealViewType,
      };

      const pageViewEvents = JSON.parse(
        localStorage.getItem('page_view_event'),
      );

      if (pageViewEvents?.length) {
        pageViewEvents.dealClickedPosition = dealClickedPosition;
        pageViewEvents.dealViewType = dealViewType;
        localStorage.setItem('page_view_event', JSON.stringify(pageViewEvents));
      }

      localStorage.setItem('page_view_event', JSON.stringify(dodObject));

      setTimeout(() => {
        updateDod(dodObject);
      }, 3_500);
    }
  }
};

export const trackCall = async (eventName) => {
  const dtmAvailable = await waitForDtm();
  if (!dtmAvailable) return;

  window.s.tl(true, 'o', eventName);
};

const getFormattedDateTime = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const dayOfWeek = now.getDay();
  const gmtOffset = now.getTimezoneOffset() * -1;

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${dayOfWeek} ${gmtOffset}`;
};

export const findPageViewTrackUrl = ({ category, subcategory }) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const s_version =
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'
      ? 's99540580587019'
      : 's98132958767248';
  const dtm_version =
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? 'wowdtm' : 'wowdtmint';
  const s_pageName = `subsubcat: ${category}: ${subcategory}`;
  const s_url = window.location.href;
  const s_loggedIn =
    document.cookie.split('dod_logged_in=').length > 1
      ? document.cookie.split('dod_logged_in=')[1].split(';')[0]
      : 'anonymous';
  const s_ct =
    document.cookie.split('ct=').length > 1
      ? document.cookie.split('ct=')[1].split(';')[0]
      : '';
  const s_user_agent = navigator.userAgent;
  const s_page_view_event = 'Sub Sub Cat Page View';
  const s_ito = document.location.search.includes('ito=')
    ? window.location.search.split('ito=')[1].split('&')[0]
    : '';
  const s_gclid = document.location.search.includes('gclid=')
    ? window.location.search.split('gclid=')[1].split('&')[0]
    : '';
  const s_mcid =
    document.cookie.split('s_ecid=').length > 1
      ? document.cookie.split('s_ecid=MCMID%7C')[1].split(';')[0]
      : '';
  const s_bw = window.innerWidth;
  const s_bh = window.innerHeight;
  const s_t = getFormattedDateTime();

  return `https://swa.wowcher.co.uk/b/ss/${dtm_version}/1/JS-2.23.0-LEWM/${s_version}?&AQB=1&ndh=1&pf=1&t=${s_t}&sdid=0723D9FA9D085202-60903D2C8A7A0C8E&mid=${s_mcid}&aamlh=6&vmt=518AAB48&vmf=swa.and.co.uk&ce=UTF-8&ns=associatednorthcliffedigital&cdp=3&pageName=${s_pageName}&cc=GBP&v0=${s_ito}&v105=${s_gclid}&c11=${s_pageName}&v11=${s_pageName}&c12=${s_url}&v12=${s_url}&c13=${s_loggedIn}&v13=${s_loggedIn}&c29=webapp&v29=webapp&c31=${s_ct}&v31=${s_ct}&c50=${s_user_agent}&v50=${s_user_agent}&v74=RE_W&pe=lnk_o&pev2=${s_page_view_event}&s=${s_bw}x${s_bh}&c=24&j=1.6&v=N&k=Y&bw=${s_bw}&bh=${s_bh}&mcorgid=255734B852785C5E0A490D44%40AdobeOrg&AQE=1&r=${document.referrer}`;
};
