import { COMMON_COLORS } from '../constants/common';
import livingsocial from './livingsocial';
import wowcher from './wowcher';

const valentines = {
  livingsocial: {
    ...livingsocial,
    images: {
      ...livingsocial.images,
      bgcolorwash:
        'https://resources.wowcher.co.uk/images/LS-Valentines25-Colourwash.jpg',
    },
  },
  wowcher: {
    ...wowcher,
    colors: {
      ...wowcher.colors,
      breadcrumbpale: COMMON_COLORS.black,
      primarypromotion: COMMON_COLORS.black,
      trustpilotbackground: 'transparent',
    },
    images: {
      ...wowcher.images,
      bgcolorwash:
        'https://resources.wowcher.co.uk/images/Valentines25-Colourwash.jpg',
    },
    padding: {
      trustpilotbottom_mdup: '0',
    },
    styles: {
      ...wowcher.styles,
    },
  },
};

export default valentines;
