/* eslint-disable filenames/match-regex */
export const HOTEL_URL_PARAMS = {
  arrival: 'arrival',
  departure: 'departure',
  guests: 'guests',
  product_id: 'productId',
  utm_medium: 'utm_medium',
  utm_source: 'utm_source',
};

export const HOTEL_URL_VALUES = {
  utm_medium_free: 'free-hotel-ads',
  utm_medium_paid: 'paid-hotel-ads',
  utm_source_google: 'google',
};

export const HOTEL_API_PARAMS = {
  checkIn: 'checkIn',
  checkOut: 'checkOut',
  guests: 'guests',
  product_id: 'leadInProductId',
  utm_medium: 'utmMedium',
  utm_source: 'utmSource',
};

export const HOTEL_API_VALUES = {
  utm_medium_free: 'free',
  utm_medium_paid: 'paid',
  utm_source_google: 'google',
};
