import { USER } from '../../config/constants/action-types';

const initialState = {
  basketTotal: {},
  isAuthCheckFinished: false,
  isAuthenticated: false,
  lightbox: false,
  username: '',
  userprofile: '',
};

// eslint-disable-next-line default-param-last
const user = (
  state = initialState,
  { basketTotal, type, userprofile, lightbox },
) => {
  switch (type) {
    case USER.SET_AUTHENTICATED: {
      return {
        ...state,
      };
    }
    case USER.SET_AUTH_CHECK_FINISHED: {
      return {
        ...state,
        isAuthCheckFinished: true,
      };
    }
    case USER.SET_USER: {
      return {
        ...state,
        isAuthenticated: true,
        username: userprofile.firstName || '',
        userprofile,
      };
    }
    case USER.RESET_USER: {
      return {
        ...state,
        isAuthCheckFinished: false,
        isAuthenticated: false,
        username: '',
        userprofile: {},
      };
    }
    case USER.SET_LIGHTBOX: {
      return {
        ...state,
        lightbox,
      };
    }
    case USER.SET_BASKET_TOTAL: {
      return {
        ...state,
        basketTotal,
      };
    }
    default:
      return state;
  }
};

export default user;
