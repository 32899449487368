import blackfriday from './blackfriday';
import christmas from './christmas';
import livingsocial from './livingsocial';
import valentines from './valentines';
import vip from './vip';
import wellness from './wellness';
import wowcher from './wowcher';

const themes = {
  'living-social': livingsocial,
  'livingsocial-blackfriday': blackfriday.livingsocial,
  'livingsocial-christmas': christmas.livingsocial,
  'livingsocial-valentines': valentines.livingsocial,
  vip,
  wellness,
  wowcher,
  'wowcher-blackfriday': blackfriday.wowcher,
  'wowcher-christmas': christmas.wowcher,
  'wowcher-valentines': valentines.wowcher,
};

export default themes;
