import cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import { updateDod } from './analytics';

const adminFeeTable = [
  [5, 0.5],
  [20, 0.99],
  [50, 1.99],
  [75, 2.99],
  [100, 3.99],
  [125, 4.99],
  [150, 6.99],
  [200, 7.99],
];
const calculateAdminFee = (price, adminFeeOnly = false) => {
  if (price > 200) {
    if (adminFeeOnly) return 9.99;

    return price + 9.99;
  }

  const adminFee =
    adminFeeTable.find(([threshold]) => price <= threshold)?.[1] || 0;
  if (adminFeeOnly) return Number(adminFee.toFixed(2));

  return Number((price + adminFee).toFixed(2));
};

const isNibbleDeal = (mainDeal) => {
  return mainDeal?.products?.some(
    (product) => product?.nibbleWidgetTags?.length > 0,
  );
};

const isBookingCalendarDeal = (mainDeal) => {
  return mainDeal?.productDisplay?.type === 'calendar';
};

export const setMainDealAdminFee = (mainDeal) => {
  if (isNibbleDeal(mainDeal) || isBookingCalendarDeal(mainDeal)) {
    return mainDeal;
  }
  if (mainDeal?.products?.some((product) => product?.adminFee > 0)) {
    const originalPrice = mainDeal.price.toFixed(2);
    mainDeal.price = calculateAdminFee(mainDeal.price);
    mainDeal.title = mainDeal.title.replace(originalPrice, mainDeal.price);
    mainDeal.description = mainDeal.description.replace(
      originalPrice,
      mainDeal.price,
    );
    updateDod({
      finalSplatPrice: mainDeal.price,
    });
    if (mainDeal?.vipPrice) {
      const originalVipPrice = mainDeal.vipPrice.toFixed(2);
      mainDeal.vipPrice = calculateAdminFee(mainDeal.vipPrice);
      mainDeal.vipTitle = mainDeal.vipTitle.replace(
        originalVipPrice,
        mainDeal.vipPrice,
      );
      mainDeal.vipDescription = mainDeal.vipDescription.replace(
        originalVipPrice,
        mainDeal.price,
      );
      updateDod({
        finalSplatPriceVIP: mainDeal.vipPrice,
      });
    }

    mainDeal.products.forEach((product) => {
      product.price = calculateAdminFee(product.price);
      if (product?.vipPrice) {
        product.vipPrice = calculateAdminFee(product.vipPrice);
      }

      return product;
    });
  }

  return mainDeal;
};

export const setDealsAdminFee = (deals) => {
  deals.forEach((deal) => {
    if (!isBookingCalendarDeal(deal)) {
      const originalPrice = deal.price.toFixed(2);
      deal.price = calculateAdminFee(deal.price);
      deal.title = deal.title.replace(originalPrice, deal.price);
      if (deal?.vipPrice) {
        const originalVipPrice = deal.vipPrice.toFixed(2);
        deal.vipPrice = calculateAdminFee(deal.vipPrice);
        deal.vipTitle = deal.vipTitle.replace(originalVipPrice, deal.vipPrice);
      }
    }
  });

  return deals;
};

export const allowAdminFee = (url) => {
  const adminFee = cookies.load(COOKIES.adminFee);

  return adminFee === '6' || (!url.includes('shop') && adminFee === '1');
};

export const showEstimated = ({ finalOrderAmount, price, totalAdminFee }) => {
  const estimatedAdminFee = calculateAdminFee(finalOrderAmount + price, true);

  if (estimatedAdminFee > totalAdminFee) {
    return `+ Estimated £${Number(estimatedAdminFee - totalAdminFee).toFixed(
      2,
    )} admin fee*`;
  }

  return null;
};
