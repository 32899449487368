import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import { URLLOCATIONUPDATE } from '../config/setup/setup';
import ErrorTrackingService from './errorTrackingService';

export const updateLocation = async (ct) => {
  try {
    return await axios({
      cache: false,
      method: 'GET',
      url: `${
        URLLOCATIONUPDATE[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
          process.env.NEXT_PUBLIC_SITE || 'wowcher'
        ]
      }?ct=${ct}`,
    });
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);
  }
};
