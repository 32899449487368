import dayjs from 'dayjs';
import parser from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import { DDMMYYSlashFormat } from './booking';

dayjs.extend(parser);
dayjs.extend(isoWeek);

export function nthifyNumber(int) {
  if (int > 3 && int < 21) return 'th';
  switch (int % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function formatDeliveryDate(int) {
  const date = dayjs(int);

  return date.format(`ddd D[${nthifyNumber(date.date())}] MMM`);
}

export function formatShortDate(int) {
  const date = dayjs(int);

  return date.format('DD/MM/YYYY');
}

export function getDaysInMonth(month, year) {
  const date = dayjs(`01/${month}/${year}`, 'DD/M/YYYY');

  return date.daysInMonth();
}

export function getDaysInPreviousMonth(month, year) {
  const date = dayjs(`01/${month}/${year}`, 'DD/M/YYYY').subtract(1, 'month');

  return date.daysInMonth();
}

export function getFirstDayOfMonth(month, year) {
  const date = dayjs(`01/${month}/${year}`, 'DD/M/YYYY');

  return date.isoWeekday();
}

export const dateConvertTimestampToDoMmmyyyy = (date) => {
  if (!date) return '';

  return dayjs(date).format('Do MMM YYYY');
};

export const dateConvertyyyymmddToDoMmmyyyy = (date) => {
  if (!date) return '';

  return dayjs(date, 'YYYY-MM-DD').format('Do MMM YYYY');
};
