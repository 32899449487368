/* eslint-disable max-params */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import useToast from '../components/_generic/toast/UseToast';
import {
  HOTEL_API_VALUES,
  HOTEL_API_PARAMS,
  HOTEL_URL_VALUES,
  HOTEL_URL_PARAMS,
} from '../config/constants/google-hotel-ads';
import {
  URLBOOKING,
  URLFLIGHTS,
  URLTRAVELPRODUCTS,
} from '../config/setup/setup';
import { GENERIC_FAILURE } from '../config/text/text';
import { formatCurrency } from './currency';
import {
  getDaysInMonth,
  getDaysInPreviousMonth,
  getFirstDayOfMonth,
  nthifyNumber,
} from './date';
import httpCommonHeaders from './httpCommonHeaders';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);

const LONDON_AIRPORTS = ['LGW', 'LHR', 'STN', 'LTN', 'LCY', 'SEN'];
const LONDON_CODE = 'LON';
const LONDON_DESCRIPTION = 'Any London';

const MAX_DAYS = 28;
const CLOSE_TO_MONTH_END_DAYS = 14;
export const MAX_NIGHTS_DEFAULT = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const DDMMYYYYSlashFormat = 'DD/MM/YYYY';
export const DDMMYYSlashFormat = 'DD/MM/YY';
export const DDMMYYYYDashFormat = 'DD-MM-YYYY';
const DoMMMFormat = 'Do MMM'; // e.g. 13th Mar
const DoMMMYYYYFormat = 'Do MMM YYYY'; // e.g. 13th Mar 2024

const addUrlParameter = (url, parameter, value) => {
  // eslint-disable-next-line no-negated-condition
  return `${!url.includes('?') ? '?' : '&'}${parameter}=${value}`;
};

export const addCYFParameters = (
  url,
  quantity,
  departureAirport,
  nights,
  thisDealLondonAirports,
) => {
  /* eslint-disable no-param-reassign */
  if (quantity) {
    url += addUrlParameter(url, 'quantity', quantity);
  }
  if (departureAirport) {
    if (departureAirport === LONDON_CODE) {
      if (thisDealLondonAirports) {
        thisDealLondonAirports.forEach((londonAirportCode) => {
          url += addUrlParameter(url, 'departureAirport', londonAirportCode);
        });
      }
    } else {
      url += addUrlParameter(url, 'departureAirport', departureAirport);
    }
    if (nights) {
      url += addUrlParameter(url, 'nights', nights);
    }
  }

  return url;
  /* eslint-enable no-param-reassign */
};

export const getShortMonthString = (monthIndex) => {
  let displayMonth = '';
  if (!monthIndex) {
    return displayMonth;
  }

  displayMonth = MONTHS_SHORT[monthIndex - 1];

  return displayMonth;
};

export const placeholderCalendarResponse = (newMonth, newYear, error) => {
  const now = new Date();
  const month = newMonth || now.getMonth() + 1;
  const year = newYear || now.getFullYear();
  const daysInPreviousMonth = getDaysInPreviousMonth(month, year);
  const daysInMonth = getDaysInMonth(month, year);
  const firstDayOfMonth = getFirstDayOfMonth(month, year);

  const out = [];
  const offset = daysInPreviousMonth - firstDayOfMonth + 1;
  for (let index = 1; index < firstDayOfMonth; index++) {
    out.push({ inMonth: false, label: offset + index });
  }
  for (let index = 1; index <= daysInMonth; index++) {
    out.push({ inMonth: true, label: index });
  }
  for (let index = 0; index < out.length % 7; index++) {
    out.push({ inMonth: false, label: index + 1 });
  }

  return {
    cells: out,
    displayMonth: MONTHS[month - 1],
    displayYear: year,
    errorCells: error,
  };
};

export const parseIncomingCells = (cells, currency) => {
  let foundFirst = false;

  return cells.map((cell) => {
    // we don't want leading 0
    const day = Number.parseInt(cell.dateString.split('-')[0], 10);
    const label = `${day.toString(10)}${nthifyNumber(day)}`;
    if (day && !Number.isNaN(day)) {
      if (day === 1) {
        foundFirst = !foundFirst;
      }

      return {
        additionalCostText:
          cell.additionalCostText === null ? null : cell.additionalCostText,
        date: cell.date,
        dateString: cell.dateString,
        inMonth: foundFirst,
        isMarketingCreditDeal: Boolean(cell.vipDiscountAllowed),
        key: cell.dateString,
        label,
        maxLengthOfStay: cell.maxLengthOfStay,
        minLengthOfStay: cell.minLengthOfStay,
        minPrice:
          cell.minPrice === null
            ? null
            : formatCurrency(currency, cell.minPrice, false, true),
        openForArrival: cell?.openForArrival,
        openForDeparture: cell?.openForDeparture,
        openForSale: cell?.openForSale,
        soldOut: cell.dateSoldOut || false,
        vipPrice:
          cell.vipPrice === null
            ? null
            : formatCurrency(currency, cell.vipPrice, false, true),
      };
    } else {
      throw new Error('Unexpected data');
    }
  });
};

/**
 * Convert date string to another
 *
 * @returns {string}
 * @param {string} date
 */
export const dateConvertAvailToProduct = (date) => {
  return dayjs(date, DDMMYYYYDashFormat).format(DDMMYYSlashFormat);
};

export const dateConvertSlashToDash = (date) => {
  return dayjs(date, DDMMYYSlashFormat).format(DDMMYYYYDashFormat);
};

export const dateConvertProductToDoMmmyyyy = (date) => {
  if (!date) return '';

  return dayjs(date, DDMMYYSlashFormat).format(DoMMMYYYYFormat);
};

export const dateConvertAddOneDay = (date) => {
  const originalDate = dayjs(date, DDMMYYYYDashFormat);
  const newDate = originalDate.add(1, 'day');

  return newDate.format(DDMMYYSlashFormat);
};

/**
 * Convert date string to another
 *
 * @returns {string}
 * @param {string} dateString
 * in DDMMYYSlashFormat
 */
export const addOneDayConvertToSelect = (dateString) => {
  const originalDate = dayjs(dateString, DDMMYYSlashFormat);
  const newDate = originalDate.add(1, 'day');

  return newDate.format(DDMMYYYYDashFormat);
};

/**
 * Convert date string to another
 *
 * @returns {string}
 * @param {string} dateString
 * in DDMMYYSlashFormat
 */
export const subtractOneDayConvertToSelect = (dateString) => {
  const originalDate = dayjs(dateString, DDMMYYSlashFormat);
  const newDate = originalDate.subtract(1, 'day');

  return newDate.format(DDMMYYYYDashFormat);
};

export const dateCalculateNights = (checkInDate, checkOutDate) => {
  if (!checkInDate || !checkOutDate) return '';
  const checkIn = dayjs(checkInDate, DDMMYYSlashFormat);
  const checkOut = dayjs(checkOutDate, DDMMYYSlashFormat);
  const nights = checkOut.diff(checkIn, 'day');

  return nights > 0 ? nights : '';
};

export const dateAddDays = (date, numberOfDays) => {
  return date.add(numberOfDays, 'day');
};

export const checkIsDateBefore = (
  dateOne,
  dateOneFormat,
  dateTwo,
  dateTwoFormat,
) => {
  const dateFirst = dayjs(dateOne, dateOneFormat);
  const dateSecond = dayjs(dateTwo, dateTwoFormat);

  return dateFirst < dateSecond;
};

export const checkIsSameOrBefore = (
  dateOne,
  dateOneFormat,
  dateTwo,
  dateTwoFormat,
) => {
  const dateFirst = dayjs(dateOne, dateOneFormat);
  const dateSecond = dayjs(dateTwo, dateTwoFormat);

  return dateFirst.isSameOrBefore(dateSecond);
};

export const getMonth = (dateOne, dateOneFormat) => {
  const date = dayjs(dateOne, dateOneFormat);
  if (date) {
    return date.month();
  }
  const now = new Date();
  const dateNow = dayjs(now);

  return dateNow.month();
};

export const getYear = (dateOne, dateOneFormat) => {
  const date = dayjs(dateOne, dateOneFormat);
  if (date) {
    return date.year();
  }
  const now = new Date();
  const dateNow = dayjs(now);

  return dateNow.year();
};

export const isDatesValid = (
  dateOne,
  dateOneFormat,
  dateTwo,
  dateTwoFormat,
) => {
  const now = new Date();
  const dateFirst = dayjs(dateOne, dateOneFormat);
  const dateSecond = dayjs(dateTwo, dateTwoFormat);
  const dateNow = dayjs(now);

  // checkin in the future and checkout after checkin
  return dateFirst > dateNow && dateSecond > dateFirst;
};

export const checkIsSameDayLastAvailable = (lastAvailableDate, dateToTest) => {
  const lastAvailable = dayjs(lastAvailableDate);
  const date = dayjs(dateToTest, DDMMYYYYDashFormat);

  return lastAvailable.isSame(date, 'day');
};

export const addOneDayToCheckinDate = (checkinDate) => {
  const date = dayjs(checkinDate, DDMMYYYYDashFormat);

  return date.add(1, 'day').format(DDMMYYYYDashFormat);
};

export const getIsDateInSelection = (date, selection) => {
  if (
    !date ||
    !selection ||
    !selection.checkInDate ||
    !selection.checkOutDate
  ) {
    return false;
  }

  const cellDate = dayjs(date, DDMMYYYYSlashFormat);
  const checkInDate = dayjs(selection.checkInDate, DDMMYYSlashFormat);
  const checkOutDate = dayjs(selection.checkOutDate, DDMMYYSlashFormat);

  return (
    cellDate.isSameOrAfter(checkInDate) && cellDate.isSameOrBefore(checkOutDate)
  );
};

export const getIsFirstDateInSelection = (date, selection) => {
  if (!date || !selection || !selection.checkInDate) {
    return false;
  }

  const cellDate = dayjs(date, DDMMYYYYSlashFormat);
  const checkInDate = dayjs(selection.checkInDate, DDMMYYSlashFormat);

  return cellDate.isSame(checkInDate);
};

export const getIsLastDateInSelection = (date, selection) => {
  if (!date || !selection || !selection.checkOutDate) {
    return false;
  }

  const cellDate = dayjs(date, DDMMYYYYSlashFormat);
  const checkInDate = dayjs(selection.checkOutDate, DDMMYYSlashFormat);

  return cellDate.isSame(checkInDate);
};

export const isNextMonth = (date, currentMonth) => {
  if (!date || !currentMonth) {
    return false;
  }

  const selectedMonth = Number.parseInt(date.split('-')[1], 10);
  if (selectedMonth - 1 > MONTHS.indexOf(currentMonth)) return true;

  return false;
};

export const getAvailability = async (
  deal,
  month,
  year,
  quantity,
  departureAirport,
  nights,
  thisDealLondonAirports,
  isBookingCalendarOnRedemption,
  leadProductId,
) => {
  if (!deal) {
    return placeholderCalendarResponse(month, year, true);
  }
  if (isBookingCalendarOnRedemption && !leadProductId) {
    return placeholderCalendarResponse(month, year, false);
  }

  let url = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/availability/deal/${deal.id}`;

  if (isBookingCalendarOnRedemption && leadProductId) {
    url += addUrlParameter(url, 'leadProductId', leadProductId);
  }

  if (month && year) {
    url += addUrlParameter(url, 'month', month);
    url += addUrlParameter(url, 'year', year);
  }

  url = addCYFParameters(
    url,
    quantity,
    departureAirport,
    nights,
    thisDealLondonAirports,
  );

  try {
    const response = await axios(url, {
      headers: httpCommonHeaders(),
    });
    if (
      !response ||
      !response.data ||
      !response.data.data ||
      !response.data.data.calendarDateViewVOList ||
      (response.data &&
        response.data.message &&
        response.data.message.includes('Error - No mapping data'))
    ) {
      throw new Error('data malformed');
    }
    const data = response.data.data;

    return {
      cells: parseIncomingCells(data.calendarDateViewVOList, deal.currency),
      displayMonth: data.currentMonth,
      displayYear: data.currentYear,
      lastAvailableStay: data?.lastAvailableStay,
      next:
        data.nextAvailableMonth && data.nextAvailableYear
          ? { month: data.nextAvailableMonth, year: data.nextAvailableYear }
          : null,
      prev:
        data.previousAvailableMonth && data.previousAvailableYear
          ? {
              month: data.previousAvailableMonth,
              year: data.previousAvailableYear,
            }
          : null,
    };
  } catch {
    return placeholderCalendarResponse(month, year, true);
  }
};

export const useAvailability = (
  deal,
  month,
  year,
  selection,
  quantity,
  departureAirport,
  nights,
  isReadyForCalendar,
  thisDealLondonAirports,
  isBookingCalendarOnRedemption,
  beginEndDate,
  currentHoverDate,
  isMouseLeftCalendar,
) => {
  // on initial load we don't know what month we will display so no placeholder
  const [data, setData] = useState({});
  const toast = useToast();
  const isVipUser = useSelector((state) => state.user.userprofile.vipUser);

  // get basic month display
  useEffect(() => {
    let destroyed = false;
    const run = async () => {
      // if we have month and year we can show a placeholder month
      if (month && year) {
        setData(placeholderCalendarResponse(month, year, false));
      }
      if (isReadyForCalendar) {
        const response = await getAvailability(
          deal,
          month,
          year,
          quantity,
          departureAirport,
          nights,
          thisDealLondonAirports,
          isBookingCalendarOnRedemption,
          isBookingCalendarOnRedemption
            ? selection?.dealVoucherProductId
            : null,
        );
        if (!destroyed) {
          setData(response);
          if (response.error) {
            setData(placeholderCalendarResponse(month, year, true));
            toast.addToast(GENERIC_FAILURE, 'toast-error');
          }
        }
      }
    };
    run();

    return () => {
      destroyed = true;
    };
  }, [
    toast,
    deal,
    month,
    year,
    quantity,
    departureAirport,
    nights,
    isReadyForCalendar,
    thisDealLondonAirports,
  ]);

  // augment with selection
  return useMemo(() => {
    if (data?.cells) {
      let lastSelectableDate = null;
      let lastSelectableDateFormatted = null;
      let isCheckinNearMonthEnd = false;
      const lastCellDate = dayjs.unix(data.cells[data.cells.length - 1]?.date);
      let checkInDate = null;
      let losMinDate = null;
      let losMaxDate = null;

      // Channel manager: ammend cells: before checkindate, after non available days
      if (beginEndDate && beginEndDate.checkInDate) {
        checkInDate = dayjs(beginEndDate.checkInDate, DDMMYYSlashFormat);
        if (
          lastCellDate &&
          lastCellDate.diff(checkInDate, 'day') < CLOSE_TO_MONTH_END_DAYS
        ) {
          isCheckinNearMonthEnd = true;
        }
        // default max days selection
        lastSelectableDate = dateAddDays(checkInDate, MAX_DAYS);
        lastSelectableDateFormatted = lastSelectableDate.format(
          DDMMYYSlashFormat,
        );
        // calculate last selectable date index
        data.cells.every(function (cell) {
          const thisCellDate = dayjs.unix(cell.date);
          // found an unavailable cell before lastSelectableDate so ammend lastSelectableDate
          if (
            thisCellDate > checkInDate &&
            thisCellDate < lastSelectableDate &&
            (!cell.minPrice || !cell.openForSale)
          ) {
            lastSelectableDate = dayjs.unix(cell.date);
            lastSelectableDateFormatted = lastSelectableDate.format(
              DDMMYYSlashFormat,
            );

            return false;
          }

          return true;
        });
      }

      return {
        ...data,
        cells: data.cells.map((cell) => {
          const selectable = (testCell) => {
            let isSelectable = testCell.minPrice && !testCell.soldOut;
            if (beginEndDate) {
              // Is Channel Manager so also check openForSale & openForArrival
              isSelectable =
                testCell.minPrice &&
                !testCell.soldOut &&
                testCell.openForSale &&
                testCell.openForArrival;
            }
            // if only a checkInDate is selected, isSelectable is modified slightly
            if (
              beginEndDate &&
              beginEndDate.checkInDate &&
              !beginEndDate.checkOutDate
            ) {
              const cellDate = dayjs.unix(cell.date);

              // length of stay measured from checkInDate
              if (testCell.minLengthOfStay && cellDate.isSame(checkInDate)) {
                losMinDate = dateAddDays(cellDate, testCell.minLengthOfStay);
              }
              if (testCell.maxLengthOfStay && cellDate.isSame(checkInDate)) {
                losMaxDate = dateAddDays(cellDate, testCell.maxLengthOfStay);
              }

              // checkInDate is selected so now account for openForDeparture
              if (cellDate > checkInDate) {
                isSelectable = testCell.openForDeparture;
              } else if (cellDate < checkInDate) {
                // a date prior to the checkInDate becomes the new checkInDate
                isSelectable =
                  testCell.minPrice &&
                  !testCell.soldOut &&
                  testCell.openForSale &&
                  testCell.openForArrival;
              }
              // reached a gap in availability or max days selection, subsequent cells not selectable
              if (cellDate > lastSelectableDate) {
                isSelectable = false;
              }
              if (
                cellDate.isSame(lastSelectableDate, 'day') &&
                testCell.openForDeparture
              ) {
                // lastSelectableDate cell is selectable as checkOutDate (even if soldout),
                // but only if it is openForDeparture
                isSelectable = true;
              }
              // restrict based on max length of stay
              if (losMaxDate && cellDate > losMaxDate) {
                isSelectable = false;
              }
              // restrict based on min length of stay
              if (losMinDate && cellDate < losMinDate) {
                isSelectable = false;
              }
            }

            return isSelectable;
          };

          const hovered = (testCell) => {
            let isHovered = false;
            if (
              beginEndDate &&
              beginEndDate.checkInDate &&
              !beginEndDate.checkOutDate
            ) {
              const cellDate = dayjs.unix(testCell.date);
              const hoverDate = dayjs.unix(currentHoverDate);
              const checkIn = dayjs(checkInDate, DDMMYYSlashFormat);
              // set hovered from checkin date cell to mouseEnter date cell
              if (
                cellDate.isSameOrAfter(checkIn) &&
                cellDate.isSameOrBefore(hoverDate) &&
                !isMouseLeftCalendar
              ) {
                isHovered = true;
              }
            }

            return isHovered;
          };

          if (selection) {
            const finalPrice =
              selection.vipDiscountAllowed && isVipUser
                ? selection.vipPrice
                : selection.price || selection.minPrice;

            return {
              ...cell,
              isFirstDateInSelection: getIsFirstDateInSelection(
                cell.key,
                selection,
              ),
              isLastDateInSelection: getIsLastDateInSelection(
                cell.key,
                selection,
              ),
              isSelectable: selectable(cell),
              isSelected: getIsDateInSelection(cell.key, selection),
              selectionPrice: formatCurrency(
                deal.currency,
                Number.parseFloat(finalPrice),
                false,
                true,
              ),
            };
          } else if (beginEndDate) {
            return {
              ...cell,
              isFirstDateInSelection: getIsFirstDateInSelection(
                cell.key,
                beginEndDate,
              ),
              isHovered: hovered(cell),
              isLastDateInSelection: getIsLastDateInSelection(
                cell.key,
                beginEndDate,
              ),
              isSelectable: selectable(cell),
              isSelected: getIsDateInSelection(cell.key, beginEndDate),
              selectionPrice: cell.minPrice,
            };
          } else {
            return {
              ...cell,
              isSelectable: selectable(cell),
            };
          }
        }),
        isCheckinNearMonthEnd,
        lastSelectableDate,
        lastSelectableDateFormatted,
      };
    } else {
      return data;
    }
  }, [
    beginEndDate,
    currentHoverDate,
    data,
    isMouseLeftCalendar,
    selection,
    deal.currency,
  ]);
};

export const getProductsForDateNew = async (
  deal,
  date,
  quantity,
  departureAirport,
  nights,
  isReadyForCalendar,
  thisDealLondonAirports,
  isDynamicTravelDeal,
  isDynamicTravelGift,
  checkIn,
  checkOut,
  isGoogleHotel,
  searchParameters,
) => {
  let url = `${URLTRAVELPRODUCTS}/${deal.id}?`;

  if (isDynamicTravelDeal) {
    if (isDynamicTravelGift) {
      url += addUrlParameter(url, 'gifting', 'true');
      url += addUrlParameter(url, 'nights', nights);
    } else {
      url += addUrlParameter(url, 'checkIn', checkIn);
      url += addUrlParameter(url, 'checkOut', checkOut);
    }
    url += addUrlParameter(url, 'dynamicTravelDeal', 'true');
    if (isGoogleHotel && searchParameters) {
      const leadInProductValue = searchParameters.get(
        HOTEL_URL_PARAMS.product_id,
      );
      if (leadInProductValue) {
        url += addUrlParameter(
          url,
          HOTEL_API_PARAMS.product_id,
          leadInProductValue,
        );
      }

      const paidOrFreeUrl = searchParameters.get(HOTEL_URL_PARAMS.utm_medium);
      let paidOrFreeUrlApi = '';
      if (paidOrFreeUrl === HOTEL_URL_VALUES.utm_medium_paid) {
        paidOrFreeUrlApi = HOTEL_API_VALUES.utm_medium_paid;
      }
      if (paidOrFreeUrl === HOTEL_URL_VALUES.utm_medium_free) {
        paidOrFreeUrlApi = HOTEL_API_VALUES.utm_medium_free;
      }
      url += addUrlParameter(
        url,
        HOTEL_API_PARAMS.utm_medium,
        paidOrFreeUrlApi,
      );

      const utmSource = searchParameters.get(HOTEL_URL_PARAMS.utm_source);
      url += addUrlParameter(url, HOTEL_API_PARAMS.utm_source, utmSource);
    }
  } else {
    url += addUrlParameter(url, 'startDate', date);

    url = addCYFParameters(
      url,
      quantity,
      departureAirport,
      nights,
      thisDealLondonAirports,
    );
  }
  try {
    const response = await axios(url);
    let newProducts = [];
    let newPackages = [];
    let newRoomOnly = [];
    let newMaxNights = MAX_NIGHTS_DEFAULT;

    if (
      (!isDynamicTravelDeal &&
        !response?.data?.data?.calendarProductViewVOList) ||
      (isDynamicTravelDeal && !response?.data?.data?.calendarRoomView)
    ) {
      throw new Error('data malformed');
    }
    newProducts = response.data.data.calendarProductViewVOList;
    newPackages = response.data.data.calendarRoomView;
    newRoomOnly = response.data.data.calendarRoomOnlyView;
    newMaxNights = response.data.data.supportedNumberOfNights;

    return { newMaxNights, newPackages, newProducts, newRoomOnly };
  } catch {
    return [];
  }
};

export const getProductsForDate = async (
  deal,
  date,
  quantity,
  departureAirport,
  nights,
  isReadyForCalendar,
  thisDealLondonAirports,
) => {
  let url = `${URLBOOKING}/${deal.id}/products/${date}`;

  url = addCYFParameters(
    url,
    quantity,
    departureAirport,
    nights,
    thisDealLondonAirports,
  );

  try {
    const response = await axios(url);

    if (
      !response ||
      !response.data ||
      !response.data.data ||
      !response.data.data.calendarProductViewVOList
    ) {
      throw new Error('data malformed');
    }

    return response.data.data.calendarProductViewVOList || [];
  } catch {
    return [];
  }
};

export const useProductsForDate = (
  deal,
  date,
  quantity,
  departureAirport,
  nights,
  isReadyForCalendar,
  thisDealLondonAirports,
) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let destroyed = false;
    const run = async () => {
      setData([]);
      if (date && isReadyForCalendar) {
        const response = await getProductsForDate(
          deal,
          date,
          quantity,
          departureAirport,
          nights,
          isReadyForCalendar,
          thisDealLondonAirports,
        );
        if (!destroyed) {
          setData(response);
        }
      }
    };
    run();

    return () => {
      destroyed = true;
    };
  }, [
    deal,
    date,
    quantity,
    departureAirport,
    nights,
    isReadyForCalendar,
    thisDealLondonAirports,
  ]);

  return data;
};

export const convertBookingProductToBasketProduct = (
  deal,
  product,
  quantity,
  deposit,
  flights,
) => {
  // The dates in the product are in a different format to what is required.
  const checkIn = product.checkInDate
    ? dayjs(product.checkInDate, DDMMYYSlashFormat).format(DDMMYYYYDashFormat)
    : null;
  const checkOut = product.checkOutDate
    ? dayjs(product.checkOutDate, DDMMYYSlashFormat).format(DDMMYYYYDashFormat)
    : null;

  return {
    adId: deal.adId || null,
    checkInDate: checkIn,
    checkOutDate: checkOut,
    dealType: 'calendar',
    flights: flights ? flights : null,
    id: product.dealVoucherProductId,
    numberOfNights: product?.numberOfNights,
    payDeposit: deposit || false,
    quantity: quantity || 1,
    // ...the rest is filled in by basketCreator()
  };
};

export const formatFlightsForBasket = (flights) => {
  if (!flights) return null;

  return {
    arrivalAirport: flights.arrivalAirport,
    departureAirport: flights.departureAirport,
    inbound: flights.inboundJourney,
    outbound: flights.outboundJourney,
    priceDiff: flights.priceDiff ? flights.priceDiff : 0,
  };
};

export const getPassengerOptions = () => {
  return [
    { label: '2 passengers (1 room)', quantity: 2 },
    { label: '4 passengers (2 rooms)', quantity: 4 },
    { label: '6 passengers (3 rooms)', quantity: 6 },
    { label: '8 passengers (4 rooms)', quantity: 8 },
  ];
};

export const getCurrentDealLondonAirports = (currentDealAirports) => {
  return currentDealAirports
    .filter((airport) => {
      return LONDON_AIRPORTS.includes(airport.header.slice(0, 3));
    })
    .map((airport) => {
      return airport.header.slice(0, 3);
    });
};

export const addAllLondonObject = (airports) => {
  if (
    airports.some(
      (airport) => airport.header === `${LONDON_CODE}:${LONDON_DESCRIPTION}`,
    )
  )
    return airports;
  airports.splice(0, 0, {
    header: `${LONDON_CODE}:${LONDON_DESCRIPTION}`,
    id: 0,
  });

  return airports;
};

export const getDefaultFlightForDate = async (date, productId) => {
  const dateToFormat = dayjs(date, DDMMYYYYDashFormat, true);
  const formattedDate = dayjs(dateToFormat).format('YYYY-MM-DD');
  const url = `${URLFLIGHTS}/cheapest/${productId}/${formattedDate}`;

  try {
    const response = await axios(url);
    const statusCode = response?.status;

    if (
      !response ||
      (statusCode !== 200 && statusCode !== 202 && statusCode !== 204)
    ) {
      throw new Error('data malformed');
    }

    return response.data || [];
  } catch {
    return [];
  }
};

export const getAlternativeFlightsForDate = async (
  date,
  productId,
  departureAirport,
  thisDealLondonAirports,
) => {
  if (!productId || !date) return;
  const dateToFormat = dayjs(date, DDMMYYYYDashFormat, true);
  const formattedDate = dayjs(dateToFormat).format('YYYY-MM-DD');
  let url = `${URLFLIGHTS}/${productId}/${formattedDate}`;

  url = addCYFParameters(
    url,
    null,
    departureAirport,
    null,
    thisDealLondonAirports,
  );

  try {
    const response = await axios(url);

    if (!response || !response.data) {
      throw new Error('data malformed');
    }

    return response.data.flights || null;
  } catch {
    return null;
  }
};
