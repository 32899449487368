import memoize from 'lodash/memoize';
import { PAGE_TYPES } from '../../../config/constants/page-types';
import { SEARCH_PAGE_SIZE } from '../../../config/links/links';
import { URLDEAL, URL_GEOLOCATION } from '../../../config/setup/setup';
import { allowAdminFee, setDealsAdminFee } from '../../../helpers/adminFee';
import {
  getDealApiPath,
  getSearchApiPath,
  getSpecialApiPath,
  getGiftFinderApiPath,
  getGiftForUrl,
} from '../../../helpers/api';
import { isFiltered } from '../../../helpers/filters';
import httpCommonHeaders from '../../../helpers/httpCommonHeaders';
import { isSSR } from '../../../helpers/ssr';
import { parseWowcherPath, stripOrigin } from '../../../helpers/url';
import axios from '../../_generic/axiosSplunk/axiosSplunk';

const OFFSET_DEAL_PAGE = 5;
const OFFSET_CATEGORY_PAGE = 2;
export const DEAL_FOCUS_RETRIES = [
  150,
  250,
  250,
  500,
  500,
  500,
  750,
  750,
  750,
  1_000,
  1_000,
  1_000,
  1_250,
  1_250,
  1_500,
  1_500,
];

export const _fetchDeals = async (url, allLocationsHeader) => {
  const headers = httpCommonHeaders();
  if (allLocationsHeader !== null && allLocationsHeader !== undefined) {
    headers['all-locations'] = allLocationsHeader;
  }

  const response = await axios(url, {
    headers,
    method: 'GET',
  });

  const deals = allowAdminFee(url)
    ? setDealsAdminFee(response?.data?.deals)
    : response?.data?.deals;

  // Add page number to deals
  let [, pageNr] = url.match(/page=(\d+)/) ?? [];
  pageNr = Number.parseInt(pageNr ?? 0, 10);

  const feed = deals.map((deal) => {
    deal.page = pageNr;

    return deal;
  });

  if (isFiltered() && Boolean(response?.data?.mainDeal)) {
    feed.unshift({ ...response.data.mainDeal, page: pageNr });
  }

  return feed;
};

export const fetchDeals = memoize(_fetchDeals);

export const dealsGetKeyGenerator = ({
  evergreenDealId,
  overrideOffset,
  hasSoldOutMainDeal,
  firstPageSize,
  isEmailDeal,
  isTravelDeal,
  pageSize,
  isCategoryPage = false,
  emailDealLocation,
  sideDealsLocal,
}) => {
  if (isSSR()) return () => null;

  const currentPagePath = stripOrigin(window.location.href);
  const { pageType, details } = parseWowcherPath(currentPagePath);
  const filtered = isFiltered();

  let offsetPageType =
    pageType === PAGE_TYPES.deal || pageType === PAGE_TYPES.vipDeal
      ? OFFSET_DEAL_PAGE
      : OFFSET_CATEGORY_PAGE;
  // no secondary deals for travel
  if (isTravelDeal) offsetPageType = 0;

  const getOffset = () => {
    let offset = 0;

    // if it's not filtered we offset to take into account
    // the main and secondary deals
    if (!filtered) {
      offset = offsetPageType;
    }
    if (Number.isInteger(overrideOffset)) {
      offset = overrideOffset;
    }

    // if its travel and deal page then we don't need to change the offset
    // because the travel deal has a 2x2 layout and does not show secondary deals next to main deal
    if (pageType === PAGE_TYPES.deal && details.isTravel) {
      offset = 0;
    }
    // we show an extra deal under a sold out deal so offset the feed by 1 in this case
    if (hasSoldOutMainDeal) {
      offset += 1;
    }

    return offset;
  };

  const { baseUrl, path, searchParams } = getDealApiPath({
    currentPagePath,
    dealIdOverride: evergreenDealId,
    emailDealLocation,
    isCategoryPage,
    isEmailDeal,
    offset: getOffset(),
    sideDealsLocal,
    stringOutput: false,
  });

  return (startPageNr) => (pageNr, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;
    searchParams.set('page', pageNr + startPageNr);

    if (pageNr === 0 || startPageNr === 0 || searchParams.get('page') === 0) {
      searchParams.set('offset', 0);
      if (firstPageSize) searchParams.set('pageSize', firstPageSize);
    }
    if (pageNr > 0 || startPageNr > 0) {
      searchParams.set('offset', offsetPageType);
      if (pageSize) searchParams.set('pageSize', pageSize);
    }

    return `${baseUrl}/${path}?${searchParams.toString()}`;
  };
};

export const specialGetKeyGenerator = ({ location, slug }) => {
  if (isSSR()) return () => null;

  const { baseUrl, path, searchParams } = getSpecialApiPath({ location, slug });

  return (startPageNr) => (pageNr, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;
    searchParams.set('page', pageNr + startPageNr);

    return `${baseUrl}/${path}?${searchParams.toString()}`;
  };
};

const _fetchSearchDeals = async (url) => {
  const response = await axios(url, {
    headers: httpCommonHeaders(),
    method: 'GET',
  });

  // Add page number to deals
  let [, pageNr] = url.match(/page=(\d+)/) ?? [];
  pageNr = Number.parseInt(pageNr ?? 0, 10);

  return response?.data?.deals.map((deal) => {
    deal.page = pageNr;

    return deal;
  });
};

export const fetchSearchDeals = memoize(_fetchSearchDeals);

export const searchGetKeyGenerator = ({ location, isVipSearch }) => {
  if (isSSR()) return () => null;
  const { searchParams, baseUrl, path } = getSearchApiPath({
    location,
    query: isSSR() ? '' : window.location.search,
  });

  return (startPageNr) => (pageNr, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;
    searchParams.set('page', pageNr + startPageNr);
    if (isVipSearch) {
      searchParams.set('vip', isVipSearch);
    }

    return `${baseUrl}/${path}?${searchParams.toString()}`;
  };
};

export const giftFinderGetKeyGenerator = ({ location }) => {
  if (isSSR()) return () => null;
  const { searchParams, baseUrl, path } = getGiftFinderApiPath({
    location,
    query: isSSR() ? '' : window.location.search,
  });

  const url = getGiftForUrl(baseUrl, path, searchParams);

  return (startPageNr) => (pageNr, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;
    searchParams.set('page', pageNr + startPageNr);

    return `${url}?${searchParams.toString()}`;
  };
};

/** search page fallback to local feed when there are no deals to show */
export const fallbackGetKeyGenerator = ({ location }) => {
  return (startPageNr) => (pageNr, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;
    const searchParameters = new URLSearchParams();
    searchParameters.set('pageSize', SEARCH_PAGE_SIZE);
    searchParameters.set('page', pageNr + startPageNr);

    return `${URLDEAL}/${location}?${searchParameters.toString()}`;
  };
};

export const getLocationFromSiteID = async (siteID) => {
  try {
    const { data } = await axios({
      headers: httpCommonHeaders(),
      method: 'GET',
      url: `${URL_GEOLOCATION}/${siteID}`,

      validateStatus: (status) => {
        return status < 500;
      },

      withCredentials: true,
    });

    return data;
  } catch (error) {
    console.warn(error);
    // TODO: Add error tracking
  }
};
